import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Button,
  HStack,
  IconButton,
  useToast,
} from '@chakra-ui/react'
import { FiZoomIn, FiZoomOut } from 'react-icons/fi'
import { Box, Stack } from '@chakra-ui/layout'
import { EventBus, PDFLinkService, PDFViewer } from 'pdfjs-dist/web/pdf_viewer'
import * as PDFJS from 'pdfjs-dist'
import { isEmpty, isNil, startCase } from 'lodash'
import { FluVaccineFormFields, FormProfessionalData, PDFFormField } from '../../../../../@types/professional'
import moment from 'moment/moment'

import 'pdfjs-dist/web/pdf_viewer.css';

const FluForm = () => {
  const toast = useToast()

  const pdfViewerRef = useRef<HTMLDivElement | null>(null)
  const pdfContainerRef = useRef<HTMLDivElement | null>(null)

  const [pdfViewer, setPDFViewer] = useState<PDFViewer | null>(null)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [pdfDoc, setPDFDocument] = useState<PDFJS.PDFDocumentProxy>()
  const [staticPDFDoc, setStaticPDFDocument] = useState<PDFJS.PDFDocumentProxy>()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isPDFRendered, setPDFRendered] = useState<boolean>(false)
  const [pdfData, setPDFData] = useState<Uint8Array | null>(null)
  const [fields, setFields] = useState<FluVaccineFormFields>({
    check1: {
      fieldName: 'Check1:signer',
      index: 0,
      fieldIds: [],
      value: false,
    },
    check2: {
      fieldName: 'Check2:signer',
      index: 0,
      fieldIds: [],
      value: false,
    },
    check3: {
      fieldName: 'Check3:signer',
      index: 0,
      fieldIds: [],
      value: false,
    },
    check4: {
      fieldName: 'Check4:signer',
      index: 0,
      fieldIds: [],
      value: false,
    },
    check5: {
      fieldName: 'Check5:signer',
      index: 0,
      fieldIds: [],
      value: false,
    },
    check6: {
      fieldName: 'Check6:signer',
      index: 0,
      fieldIds: [],
      value: false,
    },
    check7: {
      fieldName: 'Check7:signer',
      index: 0,
      fieldIds: [],
      value: false,
    },
    signature: {
      fieldName: 'Signature_es_:signer:signature',
      index: 0,
      fieldIds: [],
      value: '',
    },
    date: {
      fieldName: 'Date_es_:signer:date',
      index: 0,
      fieldIds: [],
      value: '',
    },
    fullName: {
      fieldName: 'Name_es_:signer:fullname',
      index: 0,
      fieldIds: [],
      value: '',
    },
    signIp: {
      fieldName: 'SignIP_es_:signer',
      index: 0,
      fieldIds: [],
      value: '',
    },
    signDatetime: {
      fieldName: 'SignDatetime_es_:signer',
      index: 0,
      fieldIds: [],
      value: '',
    },
  })
  const [areFieldsLoaded, setAreFieldsLoaded] = useState<boolean>(false)
  const [isDataApplied, setIsDataApplied] = useState<boolean>(false)
  const [professionalData, setProfessionalData] = useState<FormProfessionalData>()

  const showErrorToast = useCallback((title: string = 'Error', message: string = 'Oops! Something went wrong.') => {
    toast({
      description: message,
      title,
      status: 'error',
      duration: 10000, // 10 seconds
      isClosable: true,
      position: 'top-right',
    })
  }, [toast])

  const handleZoomClick = useCallback((type: 'in' | 'out') => {
    if (pdfViewer) {
      if (type === 'in') {
        pdfViewer.currentScale += 0.25
      } else {
        pdfViewer.currentScale -= 0.25
      }
    }
  }, [pdfViewer])

  const scrollToField = useCallback((fieldIds: string[], shouldFocus = true) => {
    const fields = fieldIds.map(fieldId => document.getElementById(`pdfjs_internal_id_${fieldId}`) as HTMLElement).filter(input => !isNil(input))

    if (fields && fields.length > 0) {
      const field = fields[0]
      field.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })

      // focus on field
      if (shouldFocus) {
        field.focus()
      }
    }
  }, [])

  const validateFormFields = useCallback((fields: FluVaccineFormFields): boolean => {
    // check all checkboxes
    if ([
      fields.check1.value,
      fields.check2.value,
      fields.check3.value,
      fields.check4.value,
      fields.check5.value,
      fields.check6.value,
      fields.check7.value,
    ].filter(value => value).length < 7) {
      showErrorToast('Missing checks', 'Please check all boxes')
      scrollToField(fields.check1.fieldIds ?? [])
      return false
    }

    // check signature
    if (isEmpty(fields.signature.value)) {
      showErrorToast('Missing signature', 'Please sign the form')
      scrollToField(fields.signature.fieldIds ?? [])
      return false
    }

    // check date
    if (isEmpty(fields.date.value) || !moment(fields.date.value, 'MM/DD/YYYY', true).isValid()) {
      showErrorToast('Missing date', 'Please enter a valid date: MM/DD/YYYY')
      scrollToField(fields.date.fieldIds ?? [])
      return false
    }

    if (!isNil(fields?.fullName) && (isEmpty(fields?.fullName.value) || !fields?.fullName.value?.includes(' '))) {
      showErrorToast('Missing name', 'Please enter your full name')
      scrollToField(fields?.fullName.fieldIds ?? [])
      return false
    }

    return true
  }, [fields, showErrorToast, scrollToField])

  const saveFieldValues = useCallback(async (pdfFields: {[fieldId: string]: {value: string | boolean}} | null) => {
    if (isNil(pdfFields) || Object.keys(pdfFields).length === 0) {
      return
    }

    const newFields = {...fields}

    Object.entries(pdfFields).forEach(([fieldId, fieldValue]) => {
      const fieldKey = Object.keys(newFields).find(key => (newFields[key] as PDFFormField<any>).fieldIds.includes(fieldId))

      if (fieldKey) {
        let newValue: string | boolean = fieldValue.value

        if (typeof newValue === 'string') {
          newValue = newValue.trim()
        }

        newFields[fieldKey].value = newValue
      }
    })

    setFields(newFields)
    return newFields
  }, [fields])

  const mirrorPDFs = useCallback(async (pdfDoc: PDFJS.PDFDocumentProxy, staticPDFDoc: PDFJS.PDFDocumentProxy) => {
    try {
      if (!pdfDoc || !staticPDFDoc) {
        return
      }

      const pdfFields: {[fieldId: string]: {value: string | boolean}} | null = await pdfDoc.annotationStorage.getAll()

      const staticPDFFieldObjects = await staticPDFDoc.getFieldObjects()

      if (isNil(pdfFields) || isNil(staticPDFFieldObjects) || Object.keys(pdfFields).length === 0) {
        return
      }

      return Promise.all(Object.entries(pdfFields).map(async ([fieldId, fieldValue], index) => {
        const fieldKey = Object.keys(fields ?? {}).find(key => (fields?.[key] as PDFFormField<any>)?.fieldIds?.includes(fieldId))

        if (fieldKey && !['signIp', 'signDatetime'].includes(fieldKey)) {
          let newValue: string | boolean = fieldValue.value

          if (typeof newValue === 'string') {
            newValue = newValue.trim()
          }

          const staticFields = staticPDFFieldObjects[fields?.[fieldKey].fieldName] as any[]

          staticFields.forEach(({id: staticFieldId}: any) => {
            staticPDFDoc.annotationStorage.setValue(staticFieldId, {
              value: newValue,
            })
          })
        }
      }))
    } catch (e) {
      console.log('[ERROR] mirrorPDFs')
      console.error(e)
    }
  }, [fields])

  const handleDoneClick = useCallback(async () => {
    try {
      if (pdfData && pdfDoc && staticPDFDoc && !isSubmitting && !isNil(fields)) {
        setIsSubmitting(true)

        let savedFields = await saveFieldValues(await pdfDoc.annotationStorage.getAll())

        if (!savedFields) {
          savedFields = {...fields}
        }
        if (!validateFormFields(savedFields)) {
          setIsSubmitting(false)
          return
        }

        let newFields = {...savedFields} as FluVaccineFormFields

        // apply professional IP and datetime
        const signDatetime = `Signing Datetime: ${moment().format('YYYY-MM-DD HH:mm:ss.SSS Z')}`
        const signIp = `Signing IP: ${professionalData?.ip ?? ''}`

        newFields.signIp.value = signIp
        newFields.signDatetime.value = signDatetime
        const staticPDFFieldObjects = await staticPDFDoc.getFieldObjects()

        if (isNil(staticPDFFieldObjects)) {
          return
        }

        pdfDoc?.annotationStorage.setValue(fields.signIp.fieldIds[0], {
          value: signIp,
        })

        fields.signIp.fieldIds.forEach(fieldId => {
          const signIpInput = document.getElementById(`pdfjs_internal_id_${fieldId}`) as HTMLInputElement
          if (signIpInput) {
            signIpInput.value = signIp
          }
        })

        pdfDoc?.annotationStorage.setValue(fields.signDatetime.fieldIds[0], {
          value: signDatetime,
        })

        fields.signDatetime.fieldIds.forEach(fieldId => {
          const signDatetimeInput = document.getElementById(`pdfjs_internal_id_${fieldId}`) as HTMLInputElement
          if (signDatetimeInput) {
            signDatetimeInput.value = signDatetime
          }
        })

        await mirrorPDFs(pdfDoc, staticPDFDoc)

        setTimeout(async () => {
          const blob = new Blob([(await staticPDFDoc?.saveDocument()).buffer])

          window.parent.postMessage({
            action: 'save-pdf',
            content: {
              blob,
              fields: newFields,
            },
          }, '*')

          // download blob as a click
          // const link = document.createElement('a')
          // link.href = window.URL.createObjectURL(blob)
          // link.download = 'training-form.pdf'
          // link.click()
          // link.remove()

          setIsSubmitting(false)
        }, 200)
      }
    } catch (e) {
      console.log('[ERROR] handleDoneClick')
      console.error(e)
    }
  }, [staticPDFDoc, pdfDoc, pdfData, fields, validateFormFields, isSubmitting, professionalData, saveFieldValues, mirrorPDFs])

  const loadFields = useCallback((pdfFields: {[x: string]: Object[]} | null) => {
    if (isNil(pdfFields)) {
      return
    }

    const newFields = {...fields}

    Object.keys(pdfFields).forEach(fieldName => {
      const fieldKeys = Object.keys(newFields).filter(key => newFields[key].fieldName === fieldName)

      if (fieldKeys.length === 0) {
        return
      }

      fieldKeys.forEach(fieldKey => {
        const field = newFields[fieldKey] as PDFFormField<any>
        field.fieldIds = []

        const pdfField = pdfFields[fieldName][0] as any

        field.fieldIds.push(pdfField.id)
        if (!isNil(pdfField.kidIds) && pdfField.kidIds.length > 0) {
          field.fieldIds = field.fieldIds.concat(pdfField.kidIds)
        }

        newFields[fieldKey] = field
      })
    })

    setFields(newFields)
    setAreFieldsLoaded(true)
  }, [fields])

  const loadPDFController = useCallback(async () => {
    const newPDFDoc = await PDFJS.getDocument({
      url: '/pdfs/flu_vaccination_declination.pdf',
      enableXfa: true,
    }).promise
    const newPDFDocMirror = await PDFJS.getDocument({
      url: '/pdfs/flu_vaccination_declination_mirror.pdf',
      enableXfa: true,
    }).promise

    loadFields(await newPDFDoc.getFieldObjects())
    setPDFData(await newPDFDoc.getData())

    const annotationStorage = newPDFDoc.annotationStorage
    annotationStorage.onSetModified = async function () {
      saveFieldValues(await newPDFDoc.annotationStorage.getAll())
      setPDFData(await newPDFDoc.saveDocument())
    }

    setPDFDocument(newPDFDoc)
    setStaticPDFDocument(newPDFDocMirror)
  }, [loadFields, saveFieldValues])

  const applyStyles = useCallback(async (pdfDoc: PDFJS.PDFDocumentProxy) => {
    const fields = await pdfDoc.getFieldObjects()

    if (fields) {
      const keys = Object.keys(fields)

      const signatureKey = keys.find(key => key.includes('Signature'))
      if (signatureKey) {
        const signatureField = fields[signatureKey][0] as any
        const signatureInputId = `pdfjs_internal_id_${signatureField.id}`

        setTimeout(() => {
          const signatureInput = document.getElementById(signatureInputId)

          if (signatureInput) {
            signatureInput.style.fontFamily = 'Sacramento'
          }
        }, 100)
      }
    }
  }, [])

  const applyData = useCallback(async (pdfDoc: PDFJS.PDFDocumentProxy, professionalData: FormProfessionalData) => {
    setIsDataApplied(true)

    const currentPDFFields = await pdfDoc.getFieldObjects()

    if (currentPDFFields) {
      const newFields = {...fields}

      const keys = Object.keys(currentPDFFields)

      const dateKey = keys.find(key => key.includes('Date_es'))
      if (dateKey) {
        const dateField = currentPDFFields[dateKey][0] as any

        const today = moment().format('MM/DD/YYYY')

        pdfDoc.annotationStorage.setValue(dateField.id, {
          value: today
        })

        const dateInput = document.getElementById(`pdfjs_internal_id_${dateField.id}`) as HTMLInputElement
        if (dateInput) {
          dateInput.value = today
        }

        newFields.date.value = today
      }

      const nameKey = keys.find(key => key.includes('Name') && key.includes('fullname'))
      if (nameKey) {
        const nameField = currentPDFFields[nameKey][0] as any

        let fullName = professionalData.firstname
        if (professionalData.middlename) {
          fullName += ' ' + professionalData.middlename
        }
        if (professionalData.lastname) {
          fullName += ' ' + professionalData.lastname
        }
        fullName = startCase(fullName.toLowerCase())

        pdfDoc.annotationStorage.setValue(nameField.id, {
          value: fullName
        })

        const nameInput = document.getElementById(`pdfjs_internal_id_${nameField.id}`) as HTMLInputElement
        if (nameInput) {
          nameInput.value = fullName
        }

        newFields.fullName.value = fullName
      }

      setFields(newFields)
    }
  }, [fields])

  useEffect(() => {
    if (pdfDoc && pdfContainerRef.current && pdfContainerRef.current && !isLoading && !isPDFRendered) {
      setLoading(true)

      ;(async () => {
        const pdfLinkService = new PDFLinkService();
        const eventBus = new EventBus()
        const pdfViewer = new PDFViewer({
          container: pdfContainerRef.current!,
          viewer: pdfViewerRef.current!,
          linkService: pdfLinkService,
          eventBus,
          textLayerMode: 0,
          annotationMode: PDFJS.AnnotationMode.ENABLE_FORMS,
          // @ts-ignore
          l10n: undefined
        })

        eventBus.on('pagerendered', (props: any) => {
          setPDFRendered(true)
          applyStyles(pdfDoc)
          applyStyles(staticPDFDoc!)
        })

        eventBus.on('annotationlayerrendered', (props: any) => {
          applyStyles(pdfDoc)
          applyStyles(staticPDFDoc!)
        })

        await pdfLinkService.setViewer(pdfViewer);
        await pdfViewer.setDocument(pdfDoc)

        setPDFViewer(pdfViewer)
        setLoading(false);

        window.parent.postMessage('pdf-ready', '*')
      })()
    }
  }, [pdfContainerRef.current, pdfViewerRef.current, pdfDoc, staticPDFDoc, isLoading, isPDFRendered, applyStyles])

  useEffect(() => {
    if (!PDFJS.GlobalWorkerOptions.workerSrc || isEmpty(PDFJS.GlobalWorkerOptions.workerSrc)) {
      PDFJS.GlobalWorkerOptions.workerSrc = window.location.origin + '/pdf.worker.min.js';
      loadPDFController()
    }
  }, [loadPDFController])

  useEffect(() => {
    if (!isNil(professionalData) && !isNil(pdfDoc) && !isDataApplied && isPDFRendered && areFieldsLoaded) {
      applyData(pdfDoc, professionalData)
    }
  }, [professionalData, applyData, pdfDoc, isPDFRendered, areFieldsLoaded, isDataApplied])

  useEffect(() => {
    const messageEventHandler = (event: MessageEvent<any>) => {
      if (typeof event.data.action !== 'undefined') {
        switch (event.data.action) {
          case 'professional-data':
            setProfessionalData(event.data.content)
            setIsDataApplied(false)
            setIsSubmitting(false)
            break

          case 'form-error':
            showErrorToast('Error', event.data.content)
            setIsSubmitting(false)
            break

          default:
            break
        }
      }
    }

    window.addEventListener('message', messageEventHandler)

    return () => {
      window.removeEventListener('message', messageEventHandler)
    }
  }, [showErrorToast])

  return (
    <>
      <Stack direction={'column'} width='full' position='relative' alignItems='center' tabIndex={1}>
        <HStack height={'40px'} justify={'flex-end'} gap={2} bgColor={'gray.800'} width={'full'} position='fixed' top={0} left={0} right={0} zIndex={5}>
          <IconButton
            aria-label='Zoom Out'
            icon={<FiZoomOut color='white' />}
            variant={'ghost'}
            colorScheme='whiteAlpha'
            onClick={() => handleZoomClick('out')}
          />
          <IconButton
            aria-label='Zoom In'
            icon={<FiZoomIn color='white' />}
            variant={'ghost'}
            colorScheme='whiteAlpha'
            onClick={() => handleZoomClick('in')}
          />
        </HStack>
        <Box id="pdf-viewer-container" position={'absolute'} marginTop={'40px'} marginBottom={'48px'} flex={1} maxW={'full'} ref={pdfContainerRef} tabIndex={0}>
          <Box id="pdf-viewer" className='pdfViewer' ref={pdfViewerRef} />
        </Box>
        <Button colorScheme='orange' onClick={handleDoneClick} width='full' size='lg' position={'fixed'} bottom={0} left={0} right={0} rounded='none' zIndex={5} isLoading={isSubmitting} loadingText={'Submitting...'}>
          Submit
        </Button>
      </Stack>
    </>
  )
}

export default FluForm
