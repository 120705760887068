import { CSSProperties, FC, memo } from "react"

const TotalsTable: FC<{
  totals: string[],
  currentBalance: string,
  billableLateFeeAmount: string,
}> = ({totals, currentBalance, billableLateFeeAmount}) => {
  const bodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  }

  const totalRowHighlight: CSSProperties = {
    marginTop: "10px",
    backgroundColor: "#CCC",
    borderTop: "1px solid #000"
  }
  
  const totalRowLast: CSSProperties = {
    borderBottom: "1px solid #000"
  }
  
  const totalRow: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "stretch",
    fontSize: 10,
    borderLeft: "0.5px solid #000",
    borderRight: "0.5px solid #000",
    width: "100%"
  }
  
  const totalRowOdd: CSSProperties = {
    backgroundColor: "#eee"
  }
  
  const totalCell: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "2px",
    paddingRight: "2px",
    borderLeft: "0.5px solid #000",
    borderRight: "0.5px solid #000",
    minHeight: 15,
    flex: 1,
    textAlign: "center"
  }

  const billableLateFeeAmountDiv: CSSProperties = {
    fontSize: 8,
    marginTop: "5px",
  }
  
  const totalCellHighlight: CSSProperties = {
    minHeight: 22,
  }

  return (
    <html>
      <body style={bodyStyle}>
        <div style={{
          ...totalRow,
          ...totalRowHighlight,
        }}>
          <div style={{
            ...totalCell,
            ...totalCellHighlight,
          }}>
            AGED 0-30 DAYS
          </div>
          <div style={{
            ...totalCell,
            ...totalCellHighlight,
          }}>
            AGED 31-60 DAYS
          </div>
          <div style={{
            ...totalCell,
            ...totalCellHighlight,
          }}>
            AGED 61-90 DAYS
          </div>
          <div style={{
            ...totalCell,
            ...totalCellHighlight,
          }}>
            AGED 91+ DAYS
          </div>
          <div style={{
            ...totalCell,
            ...totalCellHighlight,
          }}>
            CURRENT BALANCE
          </div>
        </div>

        <div style={{
          ...totalRow,
          ...totalRowLast,
        }}>
          <div style={{
            ...totalCell,
          }}>{totals[0]}</div>
          <div style={{
            ...totalCell,
          }}>{totals[1]}</div>
          <div style={{
            ...totalCell,
          }}>{totals[2]}</div>
          <div style={{
            ...totalCell,
          }}>{totals[3]}</div>
          <div style={{
            ...totalCell,
          }}>{currentBalance}</div>
        </div>

        {billableLateFeeAmount && billableLateFeeAmount !== '0.00' && billableLateFeeAmount !== '0' && billableLateFeeAmount !== '$ 0.00' && billableLateFeeAmount !== '$ 0' && (
          <div style={{
            ...billableLateFeeAmountDiv,
          }}>
            {`*Billable Late Fees Accrued - ${billableLateFeeAmount} total.`}
          </div>
        )}
      </body>
    </html>
  )
}

export default memo(TotalsTable)
