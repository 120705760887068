import { FC, memo } from "react"
import { formatCurrency } from "../../../../../helpers/number.helper"
import { bodyStyle, columnWidth, paymentDetailsTableCell, paymentDetailsTableFirstCell, paymentDetailsTableGrayRow, paymentDetailsTableWhiteRow } from "../../../styles"

const PayHistoryTotalTableHTML: FC<{
  totalAmount: number
  rowStyles: 'even' | 'odd'
}> = ({totalAmount, rowStyles}) => {
  const getTotalAmount = () => {
    if (totalAmount <= 0) {
      return '$0.00'
    }

    return `${totalAmount < 0 ? '-' : ''}$${formatCurrency(totalAmount, true)}`
  }

  return (
    <html>
      <body style={bodyStyle}>
        <div style={rowStyles === 'even' ? paymentDetailsTableWhiteRow : paymentDetailsTableGrayRow}>
          <div style={paymentDetailsTableFirstCell}>
            {` `}
          </div>

          <div style={{
            ...paymentDetailsTableCell,
            width: columnWidth.amount,
          }}>{` `}</div>
        </div>

        <div style={rowStyles === 'even' ? paymentDetailsTableGrayRow : paymentDetailsTableWhiteRow}>
          <div style={{
            ...paymentDetailsTableFirstCell,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            textAlign: 'right',
          }}>{`Total Pay:`}</div>
          
          <div style={{
            ...paymentDetailsTableCell,
            width: columnWidth.amount,
          }}>{getTotalAmount()}</div>
        </div>
      </body>
    </html>
  )
}

export default memo(PayHistoryTotalTableHTML)
