import { CSSProperties, FC, memo } from "react";

interface InvoiceTotalTableRowProps {
  totalHours: string;
  invoiceTotal: string
}

const InvoiceTotalTableRow: FC<InvoiceTotalTableRowProps> = ({totalHours, invoiceTotal}) => {
  const invoiceMainTableRowStyle: CSSProperties = {
    display: 'flex', 
    flexDirection: 'row',
    marginBottom: 10
  }

  const invoiceAmountsTableCell: CSSProperties = {
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 1,
    paddingBottom: 1,
    borderLeft: '1px solid black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'right',
  }

  const invoiceAmountsTableRow: CSSProperties = {
    ...invoiceMainTableRowStyle,
    fontSize: '10px',
    marginBottom: 0,
  }

  const invoiceAmountsTextTableCell: CSSProperties = {
    ...invoiceAmountsTableCell,
    justifyContent: 'flex-start',
    textAlign: 'left'
  }

  const invoiceAmountsTableFirstCell: CSSProperties = {
    ...invoiceAmountsTextTableCell,
    borderLeftWidth: 0,
  }

  const invoiceAmountsTableWhiteRow: CSSProperties = {
    ...invoiceAmountsTableRow,
    backgroundColor: 'white',
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
    borderBottom: '1px solid black',
  }

  const totalTableRow: CSSProperties = {
    ...invoiceAmountsTableWhiteRow,
    backgroundColor: '#ffc100',
    borderTop: '1px solid black'
  }

  const columnWidth = {
    serviceDateRange: 130,
    payType: 100,
    hours: 80,
    amount: 100,
  }
  
  return (
    <div style={totalTableRow}>
      <div style={{
        ...invoiceAmountsTableFirstCell,
        width: columnWidth.serviceDateRange,
      }}>
        Total
      </div>
      <div style={{
        ...invoiceAmountsTextTableCell,
        width: columnWidth.payType,
      }}>{` `}</div>
      <div style={{
        ...invoiceAmountsTableCell,
        width: columnWidth.hours,
      }}>{totalHours}</div>
      <div style={{
        ...invoiceAmountsTableCell,
        width: columnWidth.amount,
      }}>${invoiceTotal}</div>
    </div>
  )
}

export default memo(InvoiceTotalTableRow);
