import { CSSProperties, FC, memo, useMemo } from "react";
import { FacilityAddress } from "../../../../../../../@types/facility";

const FirstPageFixedFooter: FC<{companyAddress: FacilityAddress}> = ({companyAddress}) => {
  const bodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: 8,
    marginRight: 8
  }
  
  const invoiceFooterStyle: CSSProperties = {
    color: '#2b78e4',
    fontSize: '8px',
    width: '100%',
  }

  const fullAddress = useMemo(() => {
    if (companyAddress) {
      return `${companyAddress.address1}, ${companyAddress.address2}, ${companyAddress.city}, ${companyAddress.state} ${companyAddress.zipcode}`
    }
    return ''
  }, [companyAddress])

  return (
    <html>
      <body style={bodyStyle}>
        <div style={invoiceFooterStyle}>
          <img
            style={{
              width: 50,
              display: 'block',
              marginBottom: 4,
              marginLeft: 1,
            }}
            src="/img/bravoCareLogo_desktop.png"
            alt="BravoCare Logo"
          />
          Address (not to receive payment): {fullAddress}
          <br />
          Phone number: (800) 483-0663
        </div>
      </body>
    </html>
  )
}

export default memo(FirstPageFixedFooter);
