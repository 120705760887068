import moment from "moment"
import { FC, memo } from "react"
import { PayHistoryShiftFacilitySummary } from "../../../../../../../@types/professional"
import { formatCurrency } from "../../../../../helpers/number.helper"
import { bodyStyle, columnWidth, paymentDetailsTableCell, paymentDetailsTableDateCell, paymentDetailsTableFirstCell, paymentDetailsTableGrayRow, paymentDetailsTableHightlightRow, paymentDetailsTableSubRow, paymentDetailsTableSubRowContainer, paymentDetailsTableWhiteRow, paymentDetailsTextTableCell, paymentMainRangeTextStyle } from "../../../styles"

const PayHistoryDetailsTableHTML: FC<{
  details: PayHistoryShiftFacilitySummary
  index: number
}> = ({details, index}) => {
  
  const getRowStyles = (rowStyles) => {
    return rowStyles === 'even' ? paymentDetailsTableWhiteRow : paymentDetailsTableGrayRow
  }

  return (
    <html>
      <body style={bodyStyle}>
        {index === 0 ? (
          <div style={{width: '100%'}}>
            <div 
              style={{
                ...paymentMainRangeTextStyle,
                marginTop: 10
              }}
            >
              {`Shift Worked and Pay Details:`}
            </div>

            <div style={paymentDetailsTableHightlightRow}>
              <div style={{
                ...paymentDetailsTableFirstCell,
              }}>
                {`Facility Name`}	
              </div>

              <div style={{
                ...paymentDetailsTableDateCell,
                width: columnWidth.shiftDate,
              }}>
                {`Shift Date`}
              </div>

              <div style={{
                ...paymentDetailsTextTableCell,
                width: columnWidth.payType,
              }}>
                {`Pay Type`}
              </div>

              <div style={{
                ...paymentDetailsTableCell,
                width: columnWidth.hours,
              }}>
                {`Hours Worked`}
              </div>

              <div style={{
                ...paymentDetailsTableCell,
                width: columnWidth.amount,
              }}>
                {`Pay Amount`}
              </div>

              <div style={{
                ...paymentDetailsTableCell,
                width: columnWidth.amount,
              }}>
                {`Trans Fee`}
              </div>

              <div style={{
                ...paymentDetailsTableCell,
                width: columnWidth.amount,
              }}>
                {`Total Pay`}
              </div>
            </div>
          </div>
        ) : null}

        <div style={getRowStyles(details.rowStyles)}>
          <div style={{
            ...paymentDetailsTableFirstCell,
          }}>
            {details.facility_name}
          </div>

          <div style={{
            ...paymentDetailsTableDateCell,
            width: columnWidth.shiftDate,
          }}>
            {moment(details.shift_date).format('MM/DD/YYYY')}
          </div>

          <div style={paymentDetailsTableSubRowContainer}>
            {Object.values(details.payTypes)
              .sort((a, b) => a.id < b.id ? -1 : 1)
              .map(payType => (
                <div key={payType.key} style={{
                  ...paymentDetailsTableSubRow,
                }}>
                  <div style={{
                    ...paymentDetailsTextTableCell,
                    width: columnWidth.payType,
                  }}>
                    {payType.name}
                  </div>
                  <div style={{
                    ...paymentDetailsTableCell,
                    width: columnWidth.hours,
                  }}>
                    {formatCurrency(payType.hours)}
                  </div>
                  <div style={{
                    ...paymentDetailsTableCell,
                    width: columnWidth.amount,
                  }}>
                    {payType.amount < 0 ? '-$' : '$'}{formatCurrency(payType.amount, true)}
                  </div>
                </div>
            ))}
          </div>
          
          <div style={{
            ...paymentDetailsTableCell,
            width: columnWidth.amount,
          }}>
            {` `}
          </div>

          <div style={{
            ...paymentDetailsTableCell,
            width: columnWidth.amount,
          }}>
            {` `}
          </div>
        </div>
      </body>
    </html>
  )
}

export default memo(PayHistoryDetailsTableHTML)
