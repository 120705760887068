import { Page, View } from "@react-pdf/renderer"
import { isNil } from "lodash"
import moment from "moment"
import { CSSProperties, FC, memo } from "react"
import ReactDOMServer from "react-dom/server"
import Html from "react-pdf-html"
import { FetchFacilityInvoiceDataResponse, InvoiceEntryRecord } from "../../../../../../@types/facility"
import { formatCurrency } from "../../../../helpers/number.helper"
import BreakdownTable from "./BreakdownTable"
import FirstPageFooter from "./FirstPageFooter"
import FirstPageHeader from "./FirstPageHeader"
import FirstPageMainSection from "./FirstPageMainSection"
import TotalTable from "./TotalTable"
import TotalTableRow from "./TotalTableRow"

const FirstPage: FC<FetchFacilityInvoiceDataResponse> = ({invoice, entries, jobTypes, companyAddress}) => {
  const bodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  }

  const invoiceMainTableRowStyle: CSSProperties = {
    display: 'flex', 
    flexDirection: 'row',
    marginBottom: 10
  }

  const invoiceMainRangeTextStyle: CSSProperties = {
    fontSize: '10px',
    marginBottom: 4,
  }

  const invoiceAmountsTableRow: CSSProperties = {
    ...invoiceMainTableRowStyle,
    fontSize: '10px',
    marginBottom: 0,
  }

  const invoiceAmountsTableHightlightRow: CSSProperties = {
    ...invoiceAmountsTableRow,
    border: '1px solid black',
    backgroundColor: '#2b78e4',
    color: 'white'
  }

  const invoiceAmountsTableWhiteRow: CSSProperties = {
    ...invoiceAmountsTableRow,
    backgroundColor: 'white',
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
  }

  const invoiceAmountsTableGrayRow: CSSProperties = {
    ...invoiceAmountsTableWhiteRow,
    backgroundColor: '#cccccc',
  }

  const invoiceAmountsTableCell: CSSProperties = {
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 1,
    paddingBottom: 1,
    borderLeft: '1px solid black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'right',
  }

  const invoiceAmountsTextTableCell: CSSProperties = {
    ...invoiceAmountsTableCell,
    justifyContent: 'flex-start',
    textAlign: 'left'
  }

  const invoiceAmountsTableFirstCell: CSSProperties = {
    ...invoiceAmountsTextTableCell,
    borderLeftWidth: 0,
  }

  const invoiceAmountsTableCaptionStyle: CSSProperties = {
    fontSize: '10px',
    marginTop: 2,
  }

  const footerViewStyle: any = {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 20
  }

  const [mainPeriodEntries, mainPeriodTotalHours, mainPeriodTotalAmount, previousPeriodEntries, previousPeriodTotalHours, previousPeriodTotalAmount, totalHours] = entries.reduce((result: [
    (InvoiceEntryRecord & {key: string})[],
    number,
    number,
    (InvoiceEntryRecord & {key: string})[],
    number,
    number,
    number,
  ], entry) => {
    const entryWithKey = {
      ...entry,
      key: `entry-${entry.invoice_period_id}-${entry.pay_type_id}`
    }

    if (entry.invoice_period_id === invoice.invoice_period_id) {
      result[0].push(entryWithKey)

      if (!isNil(entryWithKey.hours_worked)) {
        result[1] += parseFloat(entryWithKey.hours_worked.toString())
      }

      if (!isNil(entryWithKey.total_amount)) {
        result[2] += parseFloat(entryWithKey.total_amount.toString())
      }
    } else {
      result[3].push(entryWithKey)

      if (!isNil(entryWithKey.hours_worked)) {
        result[4] += parseFloat(entryWithKey.hours_worked.toString())
      }

      if (!isNil(entryWithKey.total_amount)) {
        result[5] += parseFloat(entryWithKey.total_amount.toString())
      }
    }

    if (!isNil(entryWithKey.hours_worked)) {
      result[6] += parseFloat(entryWithKey.hours_worked.toString())
    }

    return result
  }, [[], 0.00, 0.00, [], 0.00, 0.00, 0.00])

  const columnWidth = {
    serviceDateRange: 130,
    payType: 100,
    hours: 80,
    amount: 100,
  }

  const timesheetFileMention = `Description: The breakdown is attached via excel document titled "${invoice.credit_memo ? '' : 'Invoice - '}${invoice.invoice_no} - Timesheet.csv".`

  return (
    <Page size='LETTER' orientation="portrait" style={{ paddingVertical: 20, paddingHorizontal: 8, paddingBottom: 40 }}>
      <View wrap={false}>
        <Html resetStyles>
          {ReactDOMServer.renderToStaticMarkup(
            <FirstPageHeader 
              invoiceNumber={invoice.invoice_no} 
              invoiceDate={invoice.invoice_date} 
              termDesc={invoice.term_desc} 
              isCreditMemo={isNil(invoice.credit_memo) ? false : invoice.credit_memo}
            />
          )}
        </Html>
      </View>

      <View wrap={false}>
        <Html resetStyles>
          {ReactDOMServer.renderToStaticMarkup(
            <FirstPageMainSection invoice={invoice} />
          )}
        </Html>
      </View>
      
      {previousPeriodEntries.map((entry, index, array) => {
        let rowStyles = index % 2 === 0 ? invoiceAmountsTableWhiteRow : invoiceAmountsTableGrayRow

        return (
          <View wrap={false} key={entry.key}>
            <Html resetStyles>
              {ReactDOMServer.renderToStaticMarkup(
                <html>
                  <body style={bodyStyle}>
                    {index === 0 ? (
                      <div>
                        <div style={invoiceAmountsTableHightlightRow}>
                          <div style={{
                            ...invoiceAmountsTableFirstCell,
                            width: columnWidth.serviceDateRange,
                          }}>Service Date Range</div>
                          <div style={{
                            ...invoiceAmountsTextTableCell,
                            width: columnWidth.payType,
                          }}>Pay Type</div>
                          <div style={{
                            ...invoiceAmountsTableCell,
                            width: columnWidth.hours,
                          }}>Hours</div>
                          <div style={{
                            ...invoiceAmountsTableCell,
                            width: columnWidth.amount,
                          }}>Amount</div>
                        </div>
                      </div>
                    ) : null}

                    <div style={{
                      ...rowStyles,
                    }}>
                      <div style={{
                        ...invoiceAmountsTableFirstCell,
                        width: columnWidth.serviceDateRange,
                      }}>
                        {moment(entry.week_start_date).format('M/D/YYYY')} to {moment(entry.week_end_date).format('M/D/YYYY')}
                      </div>
                      <div style={{
                        ...invoiceAmountsTextTableCell,
                        width: columnWidth.payType,
                      }}>{entry.pay_type_name}</div>
                      <div style={{
                        ...invoiceAmountsTableCell,
                        width: columnWidth.hours,
                      }}>{formatCurrency(entry.hours_worked)}</div>
                      <div style={{
                        ...invoiceAmountsTableCell,
                        width: columnWidth.amount,
                      }}>${formatCurrency(entry.total_amount)}</div>
                    </div>
                  </body>
                </html>
              )}
            </Html>
          </View>
        )
      })}

      {
        mainPeriodEntries.map((entry, index, array) => {
          const rowIndex = previousPeriodEntries.length + index

          const rowStyles = rowIndex % 2 === 0 ? invoiceAmountsTableWhiteRow : invoiceAmountsTableGrayRow

          return (
            <View key={entry.key} wrap={false}>
              <Html resetStyles>
                {ReactDOMServer.renderToStaticMarkup(
                  <html>
                    <body style={bodyStyle}>
                      {rowIndex === 0 ? (
                        <div>
                          <div style={invoiceAmountsTableHightlightRow}>
                            <div style={{
                              ...invoiceAmountsTableFirstCell,
                              width: columnWidth.serviceDateRange,
                            }}>Service Date Range</div>
                            <div style={{
                              ...invoiceAmountsTextTableCell,
                              width: columnWidth.payType,
                            }}>Pay Type</div>
                            <div style={{
                              ...invoiceAmountsTableCell,
                              width: columnWidth.hours,
                            }}>Hours</div>
                            <div style={{
                              ...invoiceAmountsTableCell,
                              width: columnWidth.amount,
                            }}>Amount</div>
                          </div>
                        </div>
                      ) : null}

                      <div style={{
                        ...rowStyles,
                      }}>
                        <div style={{
                          ...invoiceAmountsTableFirstCell,
                          width: columnWidth.serviceDateRange,
                        }}>
                          {moment(invoice.week_start_date).format('M/D/YYYY')} to {moment(invoice.week_end_date).format('M/D/YYYY')}
                        </div>
                        <div style={{
                          ...invoiceAmountsTextTableCell,
                          width: columnWidth.payType,
                        }}>{entry.pay_type_name}</div>
                        <div style={{
                          ...invoiceAmountsTableCell,
                          width: columnWidth.hours,
                        }}>{formatCurrency(entry.hours_worked)}</div>
                        <div style={{
                          ...invoiceAmountsTableCell,
                          width: columnWidth.amount,
                        }}>${formatCurrency(entry.total_amount)}</div>
                      </div>
                    </body>
                  </html>
                )}
              </Html>
            </View>
          )
        })
      }

      {entries.length > 0 ? (
        <View wrap={false}>
          <Html resetStyles>
            {ReactDOMServer.renderToStaticMarkup(
              <html>
                <body style={bodyStyle}>
                  <TotalTableRow totalHours={formatCurrency(totalHours)} invoiceTotal={formatCurrency(invoice.invoice_amount)} />
                  <div style={invoiceAmountsTableCaptionStyle}>{timesheetFileMention}</div>
                </body>
              </html>
            )}
          </Html>
        </View>
      ) : null}

      {entries.length === 0 && !isNil(invoice.memo) && invoice.memo.length > 0 ? (
        <View wrap={false}>
          <Html resetStyles>
            {ReactDOMServer.renderToStaticMarkup(
              <html>
                <body style={bodyStyle}>
                  <div style={invoiceAmountsTableCaptionStyle}>Description: {invoice.memo}</div>
                </body>
              </html>
            )}
          </Html>
        </View>
      ) : null}

      {entries.length === 0 ? (
        <View wrap={false}>
          <Html resetStyles>
            {ReactDOMServer.renderToStaticMarkup(
              <html>
                <body style={bodyStyle}>
                  <TotalTable isCreditMemo={invoice.credit_memo} totalHours={formatCurrency(totalHours)} invoiceTotal={formatCurrency(invoice.invoice_amount)} />
                </body>
              </html>
            )}
          </Html>
        </View>
      ) : null}

      {jobTypes && jobTypes.length > 0 ? (
        <View wrap={false}>
          <Html resetStyles>
            {ReactDOMServer.renderToStaticMarkup(
              <BreakdownTable invoiceWeekEndDate={invoice.week_end_date} invoiceWeekStartDate={invoice.week_start_date} jobTypes={jobTypes} />
            )}
          </Html>
        </View>
      ) : null}
      
      <View wrap={false} fixed style={footerViewStyle}>
        <Html resetStyles>
          {ReactDOMServer.renderToStaticMarkup(
            <FirstPageFooter companyAddress={companyAddress}/>
          )}
        </Html>
      </View>
    </Page>
  )
}

export default memo(FirstPage)
