import { Box, Stack } from '@chakra-ui/layout';
import { Button, HStack, IconButton, useToast } from '@chakra-ui/react';
import { isEmpty, isNil, startCase } from 'lodash';
import moment from 'moment';
import * as PDFJS from 'pdfjs-dist';
import { EventBus, PDFLinkService, PDFViewer } from 'pdfjs-dist/web/pdf_viewer';
import 'pdfjs-dist/web/pdf_viewer.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FiZoomIn, FiZoomOut } from "react-icons/fi";
import { FormProfessionalData, PDFFormField, TrainingFormFields } from '../../../../../@types/professional';

// FIELDS:
// {
//   employeeName1: {
//     fieldName: 'EmployeeName1_es_:signer',
//     index: 0,
//     fieldIds: '',
//     value: '',
//   },
//   datePolicyReceived: {
//     fieldName: 'DatePolicyReceived_es_:signer:date',
//     index: 0,
//     fieldIds: '',
//     value: '',
//   },
//   signature1: {
//     fieldName: 'Signature1_es_:signer:signature',
//     index: 0,
//     fieldIds: '',
//     value: '',
//   },
//   employeeName2: {
//     fieldName: 'EmployeeName2_es_:signer',
//     index: 0,
//     fieldIds: '',
//     value: '',
//   },
//   date1: {
//     fieldName: 'Date1_es_:signer:date',
//     index: 0,
//     fieldIds: '',
//     value: '',
//   },
//   signature2: {
//     fieldName: 'Signature2_es_:signer:signature',
//     index: 0,
//     fieldIds: '',
//     value: '',
//   },
//   date2: {
//     fieldName: 'Date2_es_:signer:date',
//     index: 0,
//     fieldIds: '',
//     value: '',
//   },
//   signIp: {
//     fieldName: 'SignIP_es_:signer',
//     index: 0,
//     fieldIds: '',
//     value: '',
//   },
//   signDatetime: {
//     fieldName: 'SignDatetime_es_:signer',
//     index: 0,
//     fieldIds: '',
//     value: '',
//   },
// }

const TrainingForm = () => {
  const toast = useToast()

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const pdfContainerRef = useRef<HTMLDivElement | null>(null)
  const pdfViewerRef = useRef<HTMLDivElement | null>(null)
  const [pdfDoc, setPDFDocument] = useState<PDFJS.PDFDocumentProxy>()
  const [staticPDFDoc, setStaticPDFDocument] = useState<PDFJS.PDFDocumentProxy>()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isPDFRendered, setPDFRendered] = useState<boolean>(false)
  const [pdfData, setPDFData] = useState<Uint8Array | null>(null)
  const [professionalData, setProfessionalData] = useState<FormProfessionalData>()
  const [fields, setFields] = useState<TrainingFormFields>()
  const [pdfViewer, setPDFViewer] = useState<PDFViewer | null>(null)

  const showErrorToast = useCallback((title: string = 'Error', message: string = 'Oops! Something went wrong.') => {
    toast({
      description: message,
      title,
      status: 'error',
      duration: 10000, // 10 seconds
      isClosable: true,
      position: 'top-right',
    })
  }
  , [toast])

  const scrollToField = useCallback((fieldIds: string[], shouldFocus = true) => {
    try {
      const fields: HTMLInputElement[] = fieldIds.map(fieldId => document.getElementById(`pdfjs_internal_id_${fieldId}`)).filter(field => !isNil(field)) as HTMLInputElement[]

      if (fields && fields.length > 0) {
        const field = fields[0]

        field.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        })

        // focus on field
        if (shouldFocus) {
          field.focus()
        }
      }
    } catch (e) {
      console.log('[ERROR] scrollToField')
      console.error(e)
    }
  }, [])

  const handleZoomClick = useCallback((type: 'in' | 'out') => {
    try {
      if (pdfViewer) {
        if (type === 'in') {
          pdfViewer.currentScale += 0.25
        } else {
          pdfViewer.currentScale -= 0.25
        }
      }
    } catch (e) {
      console.log('[ERROR] handleZoomClick')
      console.error(e)
    }
  }, [pdfViewer])

  const validateFormFields = useCallback((fields: TrainingFormFields): boolean => {
    try {
      // check names
      if (!isNil(fields?.employeeName1) && (isEmpty(fields?.employeeName1.value) || !fields?.employeeName1.value?.includes(' '))) {
        showErrorToast('Missing name', 'Please enter your full name')
        scrollToField(fields?.employeeName1.fieldIds ?? [])
        return false
      }
      if (!isNil(fields?.employeeName2) && (isEmpty(fields?.employeeName2.value) || !fields?.employeeName2.value?.includes(' '))) {
        showErrorToast('Missing name', 'Please enter your full name')
        scrollToField(fields?.employeeName2.fieldIds ?? [])
        return false
      }

      // check dates
      if (!isNil(fields?.datePolicyReceived) && (isEmpty(fields?.datePolicyReceived.value) || !moment(fields?.datePolicyReceived.value, 'MM/DD/YYYY', true).isValid())) {
        showErrorToast('Missing date', 'Please enter a valid date: MM/DD/YYYY')
        scrollToField(fields?.datePolicyReceived.fieldIds ?? [])
        return false
      }

      // check initials
      const initialsFields = [fields?.initials1, fields?.initials2, fields?.initials3, fields?.initials4, fields?.initials5, fields?.initials6, fields?.initials7, fields?.initials8, fields?.initials9, fields?.initials10]
      for (let i = 0; i < initialsFields.length; i++) {
        const field = initialsFields[i]
        if (!isNil(field) && isEmpty(field.value)) {
          showErrorToast('Missing initials', 'Please enter your initials')
          scrollToField(field.fieldIds ?? [])
          return false
        }
      }

      // check emails
      const emailFields = [fields?.email1, fields?.email2, fields?.email3, fields?.email4, fields?.email5, fields?.email6]
      for (let i = 0; i < emailFields.length; i++) {
        const field = emailFields[i]
        if (!isNil(field) && isEmpty(field.value)) {
          showErrorToast('Missing email', 'Please enter your email')
          scrollToField(field.fieldIds ?? [])
          return false
        }
      }

      // check phones
      const phoneFields = [fields?.phone1, fields?.phone2, fields?.phone3, fields?.phone4, fields?.phone5, fields?.phone6]
      for (let i = 0; i < phoneFields.length; i++) {
        const field = phoneFields[i]
        if (!isNil(field) && isEmpty(field.value)) {
          showErrorToast('Missing phone', 'Please enter your phone')
          scrollToField(field.fieldIds ?? [])
          return false
        }
      }

      // check signature1
      if (!isNil(fields?.signature1) && isEmpty(fields?.signature1.value)) {
        showErrorToast('Missing signature', 'Please sign the form')
        scrollToField(fields?.signature1.fieldIds ?? [])
        return false
      }

      // check date1
      if (!isNil(fields?.date1) && (isEmpty(fields?.date1.value) || !moment(fields?.date1.value, 'MM/DD/YYYY', true).isValid())) {
        showErrorToast('Missing date', 'Please enter a valid date: MM/DD/YYYY')
        scrollToField(fields?.date1.fieldIds ?? [])
        return false
      }

      // check signature2
      if (!isNil(fields?.signature2) && isEmpty(fields?.signature2.value)) {
        showErrorToast('Missing signature', 'Please sign the form')
        scrollToField(fields?.signature2.fieldIds ?? [])
        return false
      }

      // check signature3
      if (!isNil(fields?.signature3) && isEmpty(fields?.signature3.value)) {
        showErrorToast('Missing signature', 'Please sign the form')
        scrollToField(fields?.signature3.fieldIds ?? [])
        return false
      }

      // check date2
      if (!isNil(fields?.date2) && (isEmpty(fields?.date2.value) || !moment(fields?.date2.value, 'MM/DD/YYYY', true).isValid())) {
        showErrorToast('Missing date', 'Please enter a valid date: MM/DD/YYYY')
        scrollToField(fields?.date2.fieldIds ?? [])
        return false
      }

      return true
    } catch (e) {
      console.log('[ERROR] validateFormFields')
      console.error(e)
      return false
    }
  }, [showErrorToast, scrollToField])
  
  const saveFieldValues = useCallback(async (pdfFields: {[fieldId: string]: {value: string | boolean}} | null) => {
    try {
      if (isNil(pdfFields) || isNil(fields) || Object.keys(pdfFields).length === 0) {
        return
      }

      const newFields: TrainingFormFields = {...fields}

      Object.entries(pdfFields).forEach(([fieldId, fieldValue]) => {
        const fieldKey = Object.keys(newFields).find(key => (newFields[key] as PDFFormField<any>)?.fieldIds?.includes(fieldId))

        if (fieldKey) {
          let newValue: string | boolean = fieldValue.value
  
          if (typeof newValue === 'string') {
            newValue = newValue.trim()
          }
  
          newFields[fieldKey].value = newValue
        }
      })

      setFields(newFields)
      return newFields
    } catch (e) {
      console.log('[ERROR] saveFieldValues')
      console.error(e)
    }
  }, [fields])

  const mirrorPDFs = useCallback(async (pdfDoc: PDFJS.PDFDocumentProxy, staticPDFDoc: PDFJS.PDFDocumentProxy) => {
    try {
      if (!pdfDoc || !staticPDFDoc) {
        return
      }
  
      const pdfFields: {[fieldId: string]: {value: string | boolean}} | null = await pdfDoc.annotationStorage.getAll()
  
      const staticPDFFieldObjects = await staticPDFDoc.getFieldObjects()
  
      if (isNil(pdfFields) || isNil(staticPDFFieldObjects) || Object.keys(pdfFields).length === 0) {
        return
      }

      return Promise.all(Object.entries(pdfFields).map(async ([fieldId, fieldValue], index) => {
        const fieldKey = Object.keys(fields ?? {}).find(key => (fields?.[key] as PDFFormField<any>)?.fieldIds?.includes(fieldId))

        // if field exists in staticPDFDoc, and it doesn't contain "Sign"
        if (fieldKey && !['signIp', 'signDatetime'].includes(fieldKey)) {
          let newValue: string | boolean = fieldValue.value
  
          if (typeof newValue === 'string') {
            newValue = newValue.trim()
          }
  
          const staticFields = staticPDFFieldObjects[fields?.[fieldKey].fieldName] as any[]
          
          staticFields.forEach(({id: staticFieldId}: any) => {
            staticPDFDoc.annotationStorage.setValue(staticFieldId, {
              value: newValue,
            })
          })
        }
      }))
    } catch (e) {
      console.log('[ERROR] mirrorPDFs')
      console.error(e)
    }
  }, [fields])

  const handleDoneClick = useCallback(async () => {
    try {
      if (pdfData && pdfDoc && staticPDFDoc && !isSubmitting && !isNil(fields)) {
        setIsSubmitting(true)
  
        let savedFields = await saveFieldValues(await pdfDoc.annotationStorage.getAll())

        if (!savedFields) {
          savedFields = {...fields}
        }
  
        if (!validateFormFields(savedFields)) {
          setIsSubmitting(false)
          return
        }
  
        let newFields = {...savedFields} as TrainingFormFields
  
        // apply professional IP and datetime
        const signDatetime = `Signing Datetime: ${moment().format('YYYY-MM-DD HH:mm:ss.SSS Z')}`
        const signIp = `Signing IP: ${professionalData?.ip ?? ''}`
  
        newFields.signIp.value = signIp
        newFields.signDatetime.value = signDatetime
  
        const staticPDFFieldObjects = await staticPDFDoc.getFieldObjects()
  
        if (isNil(staticPDFFieldObjects)) {
          return
        }
  
        // fill sign IP
        const signIpFields = staticPDFFieldObjects[newFields.signIp.fieldName] as any[]
        signIpFields.forEach(({id: signIpFieldId}: any) => {
          staticPDFDoc?.annotationStorage.setValue(signIpFieldId, {
            value: newFields.signIp.value,
          })
        })
  
        // fill sign datetime
        const signDatetimeFields = staticPDFFieldObjects[newFields.signDatetime.fieldName] as any[]
        signDatetimeFields.forEach(({id: signDatetimeFieldId}: any) => {
          staticPDFDoc?.annotationStorage.setValue(signDatetimeFieldId, {
            value: newFields.signDatetime.value,
          })
        })
  
        await mirrorPDFs(pdfDoc, staticPDFDoc)
  
        setTimeout(async () => {
          const blob = new Blob([(await staticPDFDoc?.saveDocument()).buffer])
  
          window.parent.postMessage({
            action: 'save-pdf',
            content: {
              blob,
              fields: newFields,
            },
          }, '*')
  
          // // download blob as a click
          // const link = document.createElement('a')
          // link.href = window.URL.createObjectURL(blob)
          // link.download = 'training-form.pdf'
          // link.click()
          // link.remove()

          setIsSubmitting(false)
        }, 200)
      }
    } catch (e) {
      console.log('[ERROR] handleDoneClick')
      console.error(e)
    }
  }, [staticPDFDoc, pdfDoc, pdfData, fields, validateFormFields, isSubmitting, professionalData, saveFieldValues, mirrorPDFs])

  const loadFields = useCallback((fields: TrainingFormFields, pdfFields: {[x: string]: Object[]} | null) => {
    try {
      if (isNil(pdfFields)) {
        return
      }
  
      Object.keys(pdfFields).forEach(fieldName => {
        const fieldKeys = Object.keys(fields).filter(key => (fields[key] as PDFFormField<any>)?.fieldName === fieldName)
  
        if (fieldKeys.length === 0) {
          return
        }
  
        fieldKeys.forEach(fieldKey => {
          const field = fields[fieldKey] as PDFFormField<any>
          field.fieldIds = [];
  
          const pdfField = pdfFields[fieldName][0] as any

          field.fieldIds.push(pdfField.id)
  
          if (!isNil(pdfField.kidIds) && pdfField.kidIds.length > 0) {
            pdfField.kidIds.forEach((kidId: string) => {
              field.fieldIds.push(kidId)
            })
          }
  
          fields[fieldKey] = field
        })
      })

      setFields(fields)
    } catch (e) {
      console.log('[ERROR] loadFields')
      console.error(e)
    }
  }, [])

  const loadPDFController = useCallback(async ({templateName, templateFields}) => {
    try {
      const newPDFDoc = await PDFJS.getDocument({
        url: templateName ?? '',
        enableXfa: true,
      }).promise
      const staticPDFDoc = await PDFJS.getDocument({
        url: templateName?.replace('.pdf', '_mirror.pdf') ?? '',
        enableXfa: true,
      }).promise
      
      const fields = await newPDFDoc.getFieldObjects()
      loadFields(JSON.parse(templateFields), fields ?? {})

      const pdfData = await newPDFDoc.getData()
      setPDFData(pdfData)
  
      const annotationStorage = newPDFDoc.annotationStorage
      annotationStorage.onSetModified = async function () {
        saveFieldValues(await newPDFDoc.annotationStorage.getAll())
        setPDFData(await newPDFDoc.saveDocument())
      }
  
      setPDFDocument(newPDFDoc)
      setStaticPDFDocument(staticPDFDoc)
    } catch (e) {
      console.log('[ERROR] loadPDFController')
      console.error(e)
    }
  }, [loadFields, saveFieldValues])

  const applyStyles = useCallback(async (pdfDoc: PDFJS.PDFDocumentProxy) => {
    try {
      const fields = await pdfDoc.getFieldObjects()

      if (fields) {
        const keys = Object.keys(fields)

        keys.filter(key => key.includes('Signature') || key.includes('Initials')).forEach(signatureKey => {
          const signatureInput = document.querySelector(`input[name="${signatureKey}"]`) as HTMLInputElement

          if (signatureInput) {
            signatureInput.style.fontFamily = 'Sacramento'
          }
        })
      }
    } catch (e) {
      console.log('[ERROR] applyStyles')
      console.error(e)
    }
  }, [])

  const applyData = useCallback(async (pdfDoc: PDFJS.PDFDocumentProxy, professionalData: FormProfessionalData) => {
    try {
      const currentPDFFields = await pdfDoc.getFieldObjects()
    
      if (currentPDFFields) {
        let newFields = {} as TrainingFormFields

        if (fields) {
          newFields = {...fields}
        }

        const keys = Object.keys(currentPDFFields)

        keys.filter(key => key.includes('Date') && !key.includes('Sign')).forEach(dateKey => {
          const dateField = currentPDFFields[dateKey][0] as any

          const today = moment().format('MM/DD/YYYY')

          pdfDoc.annotationStorage.setValue(dateField.id, {
            value: today
          })

          const dateInput = document.querySelector(`input[name="${dateKey}"]`) as HTMLInputElement
          if (dateInput) {
            dateInput.value = today
          }

          // find form field by key in newFields and set the value
          const fieldKey = Object.keys(newFields).find(key => newFields[key].fieldName === dateKey)
          if (fieldKey) {
            newFields[fieldKey].value = today
          }
        })

        // apply professional name
        let fullName = professionalData.firstname
        if (professionalData.middlename) {
          fullName += ' ' + professionalData.middlename
        }
        if (professionalData.lastname) {
          fullName += ' ' + professionalData.lastname
        }
        fullName = startCase(fullName.toLowerCase())

        keys.filter(key => key.includes('Name')).forEach(nameKey => {
          const nameField = currentPDFFields[nameKey][0] as any
          pdfDoc.annotationStorage.setValue(nameField.id, {
            value: fullName
          })

          const inputQuery = `input[name="${nameKey}"]`
          const nameInput = document.querySelector(inputQuery) as HTMLInputElement
          if (nameInput) {
            nameInput.value = fullName
          }

          // find form field by key in newFields and set the value
          const fieldKey = Object.keys(newFields).find(key => newFields[key].fieldName === nameKey)
          if (fieldKey) {
            newFields[fieldKey].value = fullName
          }
        })

        // apply professional email
        const email = professionalData.email
        if (!isNil(email)) {
          keys.filter(key => key.includes('Email')).forEach(emailKey => {
            const emailField = currentPDFFields[emailKey][0] as any
            pdfDoc.annotationStorage.setValue(emailField.id, {
              value: email
            })

            const inputQuery = `input[name="${emailKey}"]`
            const emailInput = document.querySelector(inputQuery) as HTMLInputElement
            if (emailInput) {
              emailInput.value = email
            }

            // find form field by key in newFields and set the value
            const fieldKey = Object.keys(newFields).find(key => newFields[key].fieldName === emailKey)
            if (fieldKey) {
              newFields[fieldKey].value = email
            }
          })
        }

        // apply professional phone
        const phone = professionalData.phone
        if (!isNil(phone)) {
          keys.filter(key => key.includes('Phone')).forEach(phoneKey => {
            const phoneField = currentPDFFields[phoneKey][0] as any
            pdfDoc.annotationStorage.setValue(phoneField.id, {
              value: phone
            })

            const inputQuery = `input[name="${phoneKey}"]`
            const phoneInput = document.querySelector(inputQuery) as HTMLInputElement
            if (phoneInput) {
              phoneInput.value = phone
            }

            // find form field by key in newFields and set the value
            const fieldKey = Object.keys(newFields).find(key => newFields[key].fieldName === phoneKey)
            if (fieldKey) {
              newFields[fieldKey].value = phone
            }
          })
        }

        setFields(newFields)
      }
    } catch (e) {
      console.log('[ERROR] applyData')
      console.error(e)
    }
  }, [fields])

  useEffect(() => {
    if (pdfDoc && pdfContainerRef.current && pdfViewerRef.current && !isLoading && !isPDFRendered && !isNil(fields)) {
      setLoading(true)

      ;(async () => {
        try {
          const eventBus = new EventBus()
          const pdfLinkService = new PDFLinkService({ eventBus });
          const pdfViewer = new PDFViewer({
            container: pdfContainerRef.current!,
            viewer: pdfViewerRef.current!,
            linkService: pdfLinkService,
            eventBus,
            textLayerMode: 0,
            annotationMode: PDFJS.AnnotationMode.ENABLE_FORMS,
            // @ts-ignore
            l10n: undefined,
          })

          eventBus.on('pagerendered', (props: any) => {
            setPDFRendered(true)
            applyStyles(pdfDoc)

            if (professionalData) {
              applyData(pdfDoc, professionalData)
            }
          })

          eventBus.on('annotationlayerrendered', (props: any) => {
            applyStyles(pdfDoc)

            if (professionalData) {
              applyData(pdfDoc, professionalData)
            }
          })

          await pdfLinkService.setViewer(pdfViewer);
          await pdfViewer.setDocument(pdfDoc)
          
          setPDFViewer(pdfViewer)
          setLoading(false);
          
          window.parent.postMessage('pdf-ready', '*')
        } catch (e) {
          console.log('[ERROR] load PDF Viewer')
          console.error(e)
        }
      })()
    }
  }, [pdfDoc, isLoading, isPDFRendered, applyStyles, fields, applyData, professionalData])

  useEffect(() => {
    if (!PDFJS.GlobalWorkerOptions.workerSrc || isEmpty(PDFJS.GlobalWorkerOptions.workerSrc)) {
      try {
        PDFJS.GlobalWorkerOptions.workerSrc = window.location.origin + '/pdf.worker.min.js';
        window.parent.postMessage('modal-ready', '*')
      } catch (e) {
        console.log('[ERROR] load PDFJS Worker')
        console.error(e)
      }
    }
  }, [])

  // // ZOOM IN AND ZOOM OUT TO FIX POSSIBLE MISRENDERED IMAGE
  // useEffect(() => {
  //   if (isPDFRendered && pdfViewer) {
  //     // zoom in and zoom out to fix possible misrendered image
  //     pdfViewer.currentScale += 0.25
  //     pdfViewer.currentScale -= 0.25
  //   }
  // }, [isPDFRendered, pdfViewer])

  useEffect(() => {
    const messageEventHandler = (event: MessageEvent<any>) => {
      if (typeof event.data.action !== 'undefined') {
        try {
          switch (event.data.action) {
            case 'log':
              console.log('message-log', event.data.content)
              break
  
            case "template-data":
              loadPDFController(event.data.content)
              break
  
            case 'professional-data':
              setProfessionalData(event.data.content)
              setIsSubmitting(false)

              if (pdfDoc && isPDFRendered) {
                applyData(pdfDoc, event.data.content)
              }
              break
  
            case 'form-error':
              showErrorToast('Error', event.data.content)
              setIsSubmitting(false)
              break
  
            default:
              break
          }
        } catch (e) {
          console.log('[ERROR] messageEventHandler')
          console.error(e)
        }
      }
    }

    window.addEventListener('message', messageEventHandler)

    return () => {
      window.removeEventListener('message', messageEventHandler)
    }
  }, [showErrorToast, loadPDFController, pdfDoc, applyData, isPDFRendered])

  return (
    <Stack direction={'column'} width='full' position='relative' alignItems='center' tabIndex={1}>
      <HStack height={'40px'} justify={'flex-end'} gap={2} bgColor={'gray.800'} width={'full'} position='fixed' top={0} left={0} right={0} zIndex={5}>
        <IconButton
          aria-label='Zoom Out'
          icon={<FiZoomOut color='white' />}
          variant={'ghost'}
          colorScheme='whiteAlpha'
          onClick={() => handleZoomClick('out')}
        />
        <IconButton
          aria-label='Zoom In'
          icon={<FiZoomIn color='white' />}
          variant={'ghost'}
          colorScheme='whiteAlpha'
          onClick={() => handleZoomClick('in')}
        />
      </HStack>
      <Box id="pdf-viewer-container" position={'absolute'} marginTop={'40px'} marginBottom={'48px'} flex={1} maxW={'full'} ref={pdfContainerRef} tabIndex={0}>
        <Box id="pdf-viewer" className='pdfViewer' ref={pdfViewerRef} />
      </Box>
      <Button colorScheme='orange' onClick={handleDoneClick} width='full' size='lg' position={'fixed'} bottom={0} left={0} right={0} rounded='none' zIndex={5} isLoading={isSubmitting} loadingText={'Submitting...'}>
        Submit
      </Button>
    </Stack>
  );
}

export default TrainingForm;
