import { Box, Stack } from '@chakra-ui/layout';
import { Button, HStack, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, RadioGroup, VStack, useToast } from '@chakra-ui/react';
import { isEmpty, isNil, startCase } from 'lodash';
import moment from 'moment';
import * as PDFJS from 'pdfjs-dist';
import { EventBus, PDFLinkService, PDFViewer } from 'pdfjs-dist/web/pdf_viewer';
import 'pdfjs-dist/web/pdf_viewer.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FiZoomIn, FiZoomOut } from "react-icons/fi";
import { FormProfessionalData, PDFFormField, W9FormFields } from '../../../../../@types/professional';
import RadioAddressSuggestionOption from './RadioAddressSuggestionOption';

export enum W9AddressSuggestionOption {
  original = 'original',
  suggested = 'suggested'
}

const W9Form = () => {
  const toast = useToast()

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const pdfContainerRef = useRef<HTMLDivElement | null>(null)
  const pdfViewerRef = useRef<HTMLDivElement | null>(null)
  const [pdfDoc, setPDFDocument] = useState<PDFJS.PDFDocumentProxy>()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isPDFRendered, setPDFRendered] = useState<boolean>(false)
  const [pdfData, setPDFData] = useState<Uint8Array | null>(null)
  const [professionalData, setProfessionalData] = useState<FormProfessionalData>()
  const [addressSuggestion, setAddressSuggestion] = useState<{address: string, cityStateZip: string}>()
  const [selectedAddress, setSelectedAddress] = useState<{address: string, cityStateZip: string}>()
  const [selectedAddressSuggestionOption, setSelectedAddressSuggestionOption] = useState<W9AddressSuggestionOption>(W9AddressSuggestionOption.original)
  const [hasConfirmedAddress, setHasConfirmedAddress] = useState<boolean>(false)
  const [isAddressSuggestionModalOpen, setIsAddressSuggestionModalOpen] = useState<boolean>(false)
  const [fields, setFields] = useState<W9FormFields>({
    name: {
      fieldName: 'Name_es_:signer:fullname',
      index: 0,
      fieldIds: [],
      value: '',
    },
    businessName: {
      fieldName: 'BusinessName_es_:signer:company',
      index: 0,
      fieldIds: [],
      value: '',
    },
    individualSole: {
      fieldName: 'PersonType_es_:signer',
      index: 0,
      fieldIds: [],
      value: false,
    },
    cCorporation: {
      fieldName: 'PersonType_es_:signer',
      index: 1,
      fieldIds: [],
      value: false,
    },
    sCorporation: {
      fieldName: 'PersonType_es_:signer',
      index: 2,
      fieldIds: [],
      value: false,
    },
    partnership: {
      fieldName: 'PersonType_es_:signer',
      index: 3,
      fieldIds: [],
      value: false,
    },
    trustState: {
      fieldName: 'PersonType_es_:signer',
      index: 4,
      fieldIds: [],
      value: false,
    },
    llc: {
      fieldName: 'PersonType_es_:signer',
      index: 5,
      fieldIds: [],
      value: false,
    },
    llcType: {
      fieldName: 'LLCType_es_:signer',
      index: 0,
      fieldIds: [],
      value: '',
    },
    otherPersonType: {
      fieldName: 'PersonType_es_:signer',
      index: 6,
      fieldIds: [],
      value: false,
    },
    otherPersonTypeName: {
      fieldName: 'PersonTypeOther_es_:signer',
      index: 0,
      fieldIds: [],
      value: '',
    },
    payeeCode: {
      fieldName: 'PayeeCode_es_:signer',
      index: 0,
      fieldIds: [],
      value: '',
    },
    factaCode: {
      fieldName: 'FATCACode_es_:signer',
      index: 0,
      fieldIds: [],
      value: '',
    },
    address: {
      fieldName: 'Address1_es_:signer',
      index: 0,
      fieldIds: [],
      value: '',
    },
    cityStateZip: {
      fieldName: 'Address2_es_:signer',
      index: 0,
      fieldIds: [],
      value: '',
    },
    accountNumbers: {
      fieldName: 'AccountNumbers_es_:signer',
      index: 0,
      fieldIds: [],
      value: '',
    },
    requesters: {
      fieldName: 'RequesterNameAddress_es_:signer',
      index: 0,
      fieldIds: [],
      value: '',
    },
    ssn1: {
      fieldName: 'SSN1_es_:signer',
      index: 0,
      fieldIds: [],
      value: '',
    },
    ssn2: {
      fieldName: 'SSN2_es_:signer',
      index: 0,
      fieldIds: [],
      value: '',
    },
    ssn3: {
      fieldName: 'SSN3_es_:signer',
      index: 0,
      fieldIds: [],
      value: '',
    },
    ein1: {
      fieldName: 'EIN1_es_:signer',
      index: 0,
      fieldIds: [],
      value: '',
    },
    ein2: {
      fieldName: 'EIN2_es_:signer',
      index: 0,
      fieldIds: [],
      value: '',
    },
    signature: {
      fieldName: 'Signature_es_:signer:signature',
      index: 0,
      fieldIds: [],
      value: '',
    },
    date: {
      fieldName: 'Date_es_:signer:date',
      index: 0,
      fieldIds: [],
      value: '',
    },
    signIp: {
      fieldName: 'SignIP_es_:signer',
      index: 0,
      fieldIds: [],
      value: '',
    },
    signDatetime: {
      fieldName: 'SignDatetime_es_:signer',
      index: 0,
      fieldIds: [],
      value: '',
    },
  })
  const [areFieldsLoaded, setAreFieldsLoaded] = useState<boolean>(false)
  const [pdfViewer, setPDFViewer] = useState<PDFViewer | null>(null)
  const [isDataApplied, setIsDataApplied] = useState<boolean>(false)

  const showErrorToast = useCallback((title: string = 'Error', message: string = 'Oops! Something went wrong.') => {
    toast({
      description: message,
      title,
      status: 'error',
      duration: 10000, // 10 seconds
      isClosable: true,
      position: 'top-right',
    })
  }
  , [toast])

  const scrollToField = useCallback((fieldIds: string[], shouldFocus = true) => {
    const fields = fieldIds.map(fieldId => document.getElementById(`pdfjs_internal_id_${fieldId}`) as HTMLElement).filter(input => !isNil(input))

    if (fields && fields.length > 0) {
      const field = fields[0]
      field.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })

      // focus on field
      if (shouldFocus) {
        field.focus()
      }
    }
  }, [])

  const openAddressSuggestionModal = useCallback((addressSuggestion: {address: string, cityStateZip: string}) => {
    setAddressSuggestion(addressSuggestion)
    setIsAddressSuggestionModalOpen(true)
  }, [])

  const handleCloseAddressSuggestionModal = useCallback(() => {
    setIsAddressSuggestionModalOpen(false)
  }, [])

  const handleChangeSelectedAddress = useCallback((option: W9AddressSuggestionOption) => {
    setSelectedAddressSuggestionOption(option)
  }, [])

  const handleConfirmAddress = useCallback(() => {
    let confirmedAddress: {address: string, cityStateZip: string} = {
      address: '',
      cityStateZip: '',
    }

    if (selectedAddressSuggestionOption === W9AddressSuggestionOption.suggested) {
      confirmedAddress = {
        address: (addressSuggestion?.address ?? '').trim(),
        cityStateZip: (addressSuggestion?.cityStateZip ?? '').trim(),
      }
    } else {
      confirmedAddress = {
        address: fields.address.value.trim(),
        cityStateZip: fields.cityStateZip.value.trim(),
      }
    }

    // set confirmed address
    pdfDoc?.annotationStorage.setValue(fields.address.fieldIds[0], {
      value: confirmedAddress.address,
    })

    fields.address.fieldIds.forEach(fieldId => {
      const input = document.getElementById(`pdfjs_internal_id_${fieldId}`) as HTMLInputElement
      if (input) {
        input.value = confirmedAddress.address
      }
    })

    // set confirmed city state zip
    pdfDoc?.annotationStorage.setValue(fields.cityStateZip.fieldIds[0], {
      value: confirmedAddress.cityStateZip,
    })

    fields.cityStateZip.fieldIds.forEach(fieldId => {
      const input = document.getElementById(`pdfjs_internal_id_${fieldId}`) as HTMLInputElement
      if (input) {
        input.value = confirmedAddress.cityStateZip
      }
    })

    setSelectedAddress(confirmedAddress)
    setHasConfirmedAddress(true)

    handleCloseAddressSuggestionModal()
  }, [handleCloseAddressSuggestionModal, pdfDoc, fields, addressSuggestion, selectedAddressSuggestionOption])

  const handleZoomClick = useCallback((type: 'in' | 'out') => {
    if (pdfViewer) {
      if (type === 'in') {
        pdfViewer.currentScale += 0.25
      } else {
        pdfViewer.currentScale -= 0.25
      }
    }
  }, [pdfViewer])

  const validateFormFields = useCallback((): boolean => {
    // check names
    if ([
      fields.name.value,
      fields.businessName.value,
    ].filter(value => !isEmpty(value) && value.includes(' ')).length === 0) {
      showErrorToast('Missing name', 'Please enter your full name')
      scrollToField(fields.name.fieldIds ?? [])
      return false
    }

    // check person type
    if ([
      fields.individualSole.value,
      fields.cCorporation.value,
      fields.sCorporation.value,
      fields.partnership.value,
      fields.trustState.value,
      fields.llc.value,
      fields.otherPersonType.value,
    ].filter(value => value).length === 0) {
      showErrorToast('Missing person type', 'Please select your person type')
      scrollToField(fields.individualSole.fieldIds ?? [])
      return false
    }

    // check address
    if (isEmpty(fields.address.value)) {
      showErrorToast('Missing address', 'Please enter a valid address')
      scrollToField(fields.address.fieldIds ?? [])
      return false
    }

    // check city state zip
    if (isEmpty(fields.cityStateZip.value)) {
      showErrorToast('Missing city, state, zip', 'Please enter valid city, state, and zip')
      scrollToField(fields.cityStateZip.fieldIds ?? [])
      return false
    }

    // check tin
    const ssn = [
      fields.ssn1.value,
      fields.ssn2.value,
      fields.ssn3.value,
    ].filter(item => !isEmpty(item)).join('-').replace(/\s/g, '');
    const ein = [
      fields.ein1.value.trim(),
      fields.ein2.value.trim(),
    ].filter(item => !isEmpty(item)).join('-').replace(/\s/g, '')

    if (isEmpty(ssn) && isEmpty(ein)) {
      showErrorToast('Missing TIN', 'Please enter a valid TIN')
      scrollToField(fields.ssn1.fieldIds ?? [])
      return false
    }

    // make sure only one of them has value
    if (!isEmpty(ssn) && !isEmpty(ein)) {
      showErrorToast('Invalid TIN', 'Please only enter either a SSN or EIN, not both')
      scrollToField(fields.ssn1.fieldIds ?? [])
      return false
    }

    // if ssn is filled, then check if it's valid
    if (!isEmpty(ssn) && ssn.length !== 11) {
      showErrorToast('Invalid SSN', 'Please enter a valid SSN')
      scrollToField(fields.ssn1.fieldIds ?? [])
      return false
    }

    // if ein is filled, then check if it's valid
    if (!isEmpty(ein) && ein.length !== 10) {
      showErrorToast('Invalid EIN', 'Please enter a valid EIN')
      scrollToField(fields.ein1.fieldIds ?? [])
      return false
    }

    // check signature
    if (isEmpty(fields.signature.value)) {
      showErrorToast('Missing signature', 'Please sign the form')
      scrollToField(fields.signature.fieldIds ?? [])
      return false
    }

    // check date
    if (isEmpty(fields.date.value) || !moment(fields.date.value, 'MM/DD/YYYY', true).isValid()) {
      showErrorToast('Missing date', 'Please enter a valid date: MM/DD/YYYY')
      scrollToField(fields.date.fieldIds ?? [])
      return false
    }

    return true
  }, [fields, showErrorToast, scrollToField])
  
  const saveFieldValues = useCallback(async (pdfFields: {[fieldId: string]: {value: string | boolean}} | null) => {
    if (isNil(pdfFields) || Object.keys(pdfFields).length === 0) {
      return
    }

    const newFields = {...fields}

    Object.entries(pdfFields).forEach(([fieldId, fieldValue]) => {
      const fieldKey = Object.keys(newFields).find(key => (newFields[key] as PDFFormField<any>).fieldIds.includes(fieldId))

      if (fieldKey) {
        let newValue: string | boolean = fieldValue.value

        if (typeof newValue === 'string') {
          newValue = newValue.trim()

          // if fieldkey is ssn or ein, then format the value
          if (fieldKey.includes('ssn') || fieldKey.includes('ein')) {
            newValue = newValue.replace(/\D/g, '');

            // update input value as well
            const inputElement = document.getElementById(`pdfjs_internal_id_${fieldId}`) as HTMLInputElement
            if (inputElement) {
              inputElement.value = newValue
            }
          }
        }

        newFields[fieldKey].value = newValue

        // if the address was modified and it's different from selected address, then set confirmed address as false
        if (
          hasConfirmedAddress
          && (
            (fieldKey === 'address' && newValue !== selectedAddress?.address) 
            || (fieldKey === 'cityStateZip' && newValue !== selectedAddress?.cityStateZip)
          )
        ) {
          setHasConfirmedAddress(false)
        }
      }
    })

    setFields(newFields)
  }, [fields, selectedAddress, hasConfirmedAddress])

  const handleDoneClick = useCallback(async () => {
    if (pdfData && pdfDoc && !isSubmitting) {
      setIsSubmitting(true)

      saveFieldValues(await pdfDoc.annotationStorage.getAll())

      if (!validateFormFields()) {
        setIsSubmitting(false)
        return
      }

      // apply professional IP and datetime
      const signDatetime = `Signing Datetime: ${moment().format('YYYY-MM-DD HH:mm:ss.SSS Z')}`
      const signIp = `Signing IP: ${professionalData?.ip ?? ''}`

      pdfDoc?.annotationStorage.setValue(fields.signIp.fieldIds[0], {
        value: signIp,
      })

      fields.signIp.fieldIds.forEach(fieldId => {
        const signIpInput = document.getElementById(`pdfjs_internal_id_${fieldId}`) as HTMLInputElement
        if (signIpInput) {
          signIpInput.value = signIp
        }
      })

      pdfDoc?.annotationStorage.setValue(fields.signDatetime.fieldIds[0], {
        value: signDatetime,
      })

      fields.signDatetime.fieldIds.forEach(fieldId => {
        const signDatetimeInput = document.getElementById(`pdfjs_internal_id_${fieldId}`) as HTMLInputElement
        if (signDatetimeInput) {
          signDatetimeInput.value = signDatetime
        }
      })


      setTimeout(async () => {
        const blob = new Blob([(await pdfDoc?.saveDocument()).buffer])

        window.parent.postMessage({
          action: 'save-pdf',
          content: {
            blob,
            fields,
            hasConfirmedAddress,
          },
        }, '*')

        // // download blob as a click
        // const link = document.createElement('a')
        // link.href = window.URL.createObjectURL(blob)
        // link.download = 'w9.pdf'
        // link.click()
        // link.remove()

        setIsSubmitting(false)
      }, 200)
    }
  }, [pdfDoc, pdfData, fields, validateFormFields, hasConfirmedAddress, isSubmitting, professionalData, saveFieldValues])

  const loadFields = useCallback((pdfFields: {[x: string]: Object[]} | null) => {
    if (isNil(pdfFields)) {
      return
    }

    const newFields = {...fields}

    Object.keys(pdfFields).forEach(fieldName => {
      const fieldKeys = Object.keys(newFields).filter(key => newFields[key].fieldName === fieldName)

      if (fieldKeys.length === 0) {
        return
      }

      fieldKeys.forEach(fieldKey => {
        const field = newFields[fieldKey] as PDFFormField<any>
        field.fieldIds = []

        const pdfField = pdfFields[fieldName][0] as any

        field.fieldIds.push(pdfField.id)
        if (!isNil(pdfField.kidIds) && pdfField.kidIds.length > 0) {
          field.fieldIds = field.fieldIds.concat(pdfField.kidIds)
        }

        newFields[fieldKey] = field
      })
    })

    setFields(newFields)
    setAreFieldsLoaded(true)
  }, [fields])

  const loadPDFController = useCallback(async () => {
    const newPDFDoc = await PDFJS.getDocument({
      url: '/pdfs/W-9-with-sign-n-date.pdf',
      enableXfa: true,
    }).promise

    loadFields(await newPDFDoc.getFieldObjects())
    setPDFData(await newPDFDoc.getData())

    const annotationStorage = newPDFDoc.annotationStorage
    annotationStorage.onSetModified = async function () {
      saveFieldValues(await newPDFDoc.annotationStorage.getAll())
      setPDFData(await newPDFDoc.saveDocument())
    }

    setPDFDocument(newPDFDoc)
  }, [loadFields, saveFieldValues])

  const applyStyles = useCallback(async (pdfDoc: PDFJS.PDFDocumentProxy) => {
    const fields = await pdfDoc.getFieldObjects()

    if (fields) {
      const keys = Object.keys(fields)

      const signatureKey = keys.find(key => key.includes('Signature'))
      if (signatureKey) {
        const signatureField = fields[signatureKey][0] as any
        const signatureInputId = `pdfjs_internal_id_${signatureField.id}`

        const signatureInput = document.getElementById(signatureInputId)

        if (signatureInput) {
          signatureInput.style.fontFamily = 'Sacramento'
        }
      }

      // // apply line spacing logic to ssn and ein fields
      // const digitFieldsIds = [
      //   (fields['SSN1_es_:signer'][0] as any).id,
      //   (fields['SSN2_es_:signer'][0] as any).id,
      //   (fields['SSN3_es_:signer'][0] as any).id,
      //   (fields['EIN1_es_:signer'][0] as any).id,
      //   (fields['EIN2_es_:signer'][0] as any).id,
      // ]

      // // for each digit field, get the input element and apply letter spacing
      // digitFieldsIds.forEach(fieldId => {
      //   const inputElement = document.getElementById(`pdfjs_internal_id_${fieldId}`) as HTMLInputElement
      //   if (inputElement && !inputElement.hasAttribute('updated-letter-spacing')) {
      //     const pieces = inputElement.style.letterSpacing.split('px * var')
      //     let letterSpacing = parseFloat(pieces[0].replace('calc(', '').trim())
      //     letterSpacing -= 1
      //     inputElement.style.letterSpacing = `calc(${letterSpacing}px * var${pieces[1]}`
      //     inputElement.setAttribute('updated-letter-spacing', 'true')
      //   }
      // })
    }
  }, [])

  const applyData = useCallback(async (pdfDoc: PDFJS.PDFDocumentProxy, professionalData: FormProfessionalData) => {
    setIsDataApplied(true)

    const currentPDFFields = await pdfDoc.getFieldObjects()
    
    if (currentPDFFields) {
      const newFields = {...fields}

      const keys = Object.keys(currentPDFFields)

      const dateKey = keys.find(key => key.includes('Date'))
      if (dateKey) {
        const dateField = currentPDFFields[dateKey][0] as any

        const today = moment().format('MM/DD/YYYY')

        pdfDoc.annotationStorage.setValue(dateField.id, {
          value: today
        })

        const dateInput = document.getElementById(`pdfjs_internal_id_${dateField.id}`) as HTMLInputElement
        if (dateInput) {
          dateInput.value = today
        }

        newFields.date.value = today
      }

      const nameKey = keys.find(key => key.includes('Name') && key.includes('fullname'))
      if (nameKey) {
        const nameField = currentPDFFields[nameKey][0] as any

        let fullName = professionalData.firstname
        if (professionalData.middlename) {
          fullName += ' ' + professionalData.middlename
        }
        if (professionalData.lastname) {
          fullName += ' ' + professionalData.lastname
        }
        fullName = startCase(fullName.toLowerCase())

        pdfDoc.annotationStorage.setValue(nameField.id, {
          value: fullName
        })

        const nameInput = document.getElementById(`pdfjs_internal_id_${nameField.id}`) as HTMLInputElement
        if (nameInput) {
          nameInput.value = fullName
        }

        newFields.name.value = fullName
      }

      const individualPersonTypeField = currentPDFFields['PersonType_es_:signer'][1] as any
      if (individualPersonTypeField) {
        pdfDoc.annotationStorage.setValue(individualPersonTypeField.id, {
          value: true
        })

        const individualPersonTypeInput = document.getElementById(`pdfjs_internal_id_${individualPersonTypeField.id}`) as HTMLInputElement

        if (individualPersonTypeInput) {
          individualPersonTypeInput.checked = true
        }

        newFields.individualSole.value = true
      }

      // const address1Key = keys.find(key => key.includes('Address1_es_'))
      // if (address1Key) {
      //   const address1Field = currentPDFFields[address1Key][0] as any

      //   pdfDoc.annotationStorage.setValue(address1Field.id, {
      //     value: professionalData.street
      //   })

      //   const address1Input = document.getElementById(`pdfjs_internal_id_${address1Field.id}`) as HTMLInputElement
      //   if (address1Input) {
      //     address1Input.value = professionalData.street ?? ''
      //   }

      //   newFields.address.value = professionalData.street ?? ''
      // }

      // const address2Key = keys.find(key => key.includes('Address2_es_'))
      // if (address2Key) {
      //   const address2Field = currentPDFFields[address2Key][0] as any

      //   const address2Value = `${professionalData.city}, ${professionalData.state}, ${professionalData.zip}`

      //   pdfDoc.annotationStorage.setValue(address2Field.id, {
      //     value: address2Value
      //   })

      //   const address2Input = document.getElementById(`pdfjs_internal_id_${address2Field.id}`) as HTMLInputElement
      //   if (address2Input) {
      //     address2Input.value = address2Value
      //   }

      //   newFields.cityStateZip.value = address2Value
      // }

      setFields(newFields)
    }
  }, [fields])

  useEffect(() => {
    if (pdfDoc && pdfContainerRef.current && pdfContainerRef.current && !isLoading && !isPDFRendered) {
      setLoading(true)

      ;(async () => {
        const pdfLinkService = new PDFLinkService();
        const eventBus = new EventBus()
        const pdfViewer = new PDFViewer({
          container: pdfContainerRef.current!,
          viewer: pdfViewerRef.current!,
          linkService: pdfLinkService,
          eventBus,
          textLayerMode: 0,
          annotationMode: PDFJS.AnnotationMode.ENABLE_FORMS,
          // @ts-ignore
          l10n: undefined
        })

        eventBus.on('pagerendered', (props: any) => {
          setPDFRendered(true)
          applyStyles(pdfDoc)
        })

        await pdfLinkService.setViewer(pdfViewer);
        await pdfViewer.setDocument(pdfDoc)

        setPDFViewer(pdfViewer)
        setLoading(false);
        
        window.parent.postMessage('pdf-ready', '*')
      })()
    }
  }, [pdfContainerRef.current, pdfViewerRef.current, pdfDoc, isLoading, isPDFRendered, applyStyles])

  useEffect(() => {
    if (!PDFJS.GlobalWorkerOptions.workerSrc || isEmpty(PDFJS.GlobalWorkerOptions.workerSrc)) {
      PDFJS.GlobalWorkerOptions.workerSrc = window.location.origin + '/pdf.worker.min.js';
      loadPDFController()
    }
  }, [loadPDFController])

  useEffect(() => {
    if (!isNil(professionalData) && !isNil(pdfDoc) && !isDataApplied && isPDFRendered && areFieldsLoaded) {
      applyData(pdfDoc, professionalData)
    }
  }, [professionalData, applyData, pdfDoc, isPDFRendered, areFieldsLoaded, isDataApplied])

  useEffect(() => {
    const messageEventHandler = (event: MessageEvent<any>) => {
      if (typeof event.data.action !== 'undefined') {
        switch (event.data.action) {
          case 'professional-data':
            setProfessionalData(event.data.content)
            setIsDataApplied(false)
            setIsSubmitting(false)
            break

          case 'form-error':
            showErrorToast('Error', event.data.content)
            setIsSubmitting(false)
            break

          case 'choose-address':
            openAddressSuggestionModal(event.data.content)
            setIsSubmitting(false)
            break

          default:
            break
        }
      }
    }

    window.addEventListener('message', messageEventHandler)

    return () => {
      window.removeEventListener('message', messageEventHandler)
    }
  }, [showErrorToast, openAddressSuggestionModal])

  useEffect(() => {
    // if ssn or ein is being filled and reached the limit of each input, then focus on the next input
    // in case it's the last field of them, focus the signature field

    const ssn1Input = fields.ssn1.fieldIds.reduce<HTMLInputElement | null>((result, fieldId) => result ?? (document.getElementById(`pdfjs_internal_id_${fieldId}`) as HTMLInputElement), null)
    const ssn2Input = fields.ssn2.fieldIds.reduce<HTMLInputElement | null>((result, fieldId) => result ?? (document.getElementById(`pdfjs_internal_id_${fieldId}`) as HTMLInputElement), null)
    const ssn3Input = fields.ssn3.fieldIds.reduce<HTMLInputElement | null>((result, fieldId) => result ?? (document.getElementById(`pdfjs_internal_id_${fieldId}`) as HTMLInputElement), null)
    const ein1Input = fields.ein1.fieldIds.reduce<HTMLInputElement | null>((result, fieldId) => result ?? (document.getElementById(`pdfjs_internal_id_${fieldId}`) as HTMLInputElement), null)
    const ein2Input = fields.ein2.fieldIds.reduce<HTMLInputElement | null>((result, fieldId) => result ?? (document.getElementById(`pdfjs_internal_id_${fieldId}`) as HTMLInputElement), null)
    const signatureInput = fields.signature.fieldIds.reduce<HTMLInputElement | null>((result, fieldId) => result ?? (document.getElementById(`pdfjs_internal_id_${fieldId}`) as HTMLInputElement), null)

    const ssnInputs = [ssn1Input, ssn2Input, ssn3Input]
    const einInputs = [ein1Input, ein2Input]

    // check ssn inputs
    for (const ssnInput of ssnInputs) {
      // check if the ssnInput is focused
      if (ssnInput && ssnInput === document.activeElement) {
        // check if the ssnInput has reached the limit of characters based on "maxlength" attribute
        if (ssnInput.value.length === ssnInput.maxLength) {
          // check if the next input is the last one
          const nextInput = ssnInputs[ssnInputs.indexOf(ssnInput) + 1]
          if (isNil(nextInput)) {
            // focus on signature field
            signatureInput?.focus()
          } else {
            // focus on next input
            nextInput.focus()
          }

          return
        }
      }
    }

    // check ein inputs
    for (const einInput of einInputs) {
      // check if the einInput is focused
      if (einInput && einInput === document.activeElement) {
        // check if the einInput has reached the limit of characters based on "maxlength" attribute
        if (einInput.value.length === einInput.maxLength) {
          // check if the next input is the last one
          const nextInput = einInputs[einInputs.indexOf(einInput) + 1]
          if (isNil(nextInput)) {
            // focus on signature field
            signatureInput?.focus()
          } else {
            // focus on next input
            nextInput.focus()
          }

          return
        }
      }
    }
  }, [fields])

  return (
    <>
      <Stack direction={'column'} width='full' position='relative' alignItems='center' tabIndex={1}>
        <HStack height={'40px'} justify={'flex-end'} gap={2} bgColor={'gray.800'} width={'full'} position='fixed' top={0} left={0} right={0} zIndex={5}>
          <IconButton
            aria-label='Zoom Out'
            icon={<FiZoomOut color='white' />}
            variant={'ghost'}
            colorScheme='whiteAlpha'
            onClick={() => handleZoomClick('out')}
          />
          <IconButton
            aria-label='Zoom In'
            icon={<FiZoomIn color='white' />}
            variant={'ghost'}
            colorScheme='whiteAlpha'
            onClick={() => handleZoomClick('in')}
          />
        </HStack>
        <Box id="pdf-viewer-container" position={'absolute'} marginTop={'40px'} marginBottom={'48px'} flex={1} maxW={'full'} ref={pdfContainerRef} tabIndex={0}>
          <Box id="pdf-viewer" className='pdfViewer' ref={pdfViewerRef} />
        </Box>
        <Button colorScheme='orange' onClick={handleDoneClick} width='full' size='lg' position={'fixed'} bottom={0} left={0} right={0} rounded='none' zIndex={5} isLoading={isSubmitting} loadingText={'Submitting...'}>
          Submit
        </Button>
      </Stack>
      <Modal
        isOpen={isAddressSuggestionModalOpen}
        onClose={handleCloseAddressSuggestionModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm your address</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup onChange={handleChangeSelectedAddress} value={selectedAddressSuggestionOption}>
              <VStack width={'full'} gap={4} alignItems={'flex-start'}>
                <RadioAddressSuggestionOption
                  label='What you entered'
                  address={fields.address.value}
                  cityStateZip={fields.cityStateZip.value}
                  value={W9AddressSuggestionOption.original}
                  isSelected={selectedAddressSuggestionOption === W9AddressSuggestionOption.original}
                />

                <RadioAddressSuggestionOption
                  label='Recommended'
                  address={addressSuggestion?.address ?? ''}
                  cityStateZip={addressSuggestion?.cityStateZip ?? ''}
                  value={W9AddressSuggestionOption.suggested}
                  isSelected={selectedAddressSuggestionOption === W9AddressSuggestionOption.suggested}
                />
              </VStack>
            </RadioGroup>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' onClick={handleCloseAddressSuggestionModal}>
              Cancel
            </Button>
            <Button colorScheme='orange' onClick={handleConfirmAddress}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default W9Form;
