import { CSSProperties, FC, memo } from "react"

const FirstPageMainSection: FC<{
  customer_name: string
  customer_street: string
  customer_city: string
  customer_state: string
  customer_zip: string
}> = ({
  customer_name,
  customer_street,
  customer_city,
  customer_state,
  customer_zip,
}) => {
  const bodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  }
  
  const dividerStyle: CSSProperties = {
    width: '100%', 
    height: 1, 
    backgroundColor: '#2b78e4',
    marginBottom: 10,
  }

  const infoBoxStyle: CSSProperties = {
    backgroundColor: '#f2f2f2',
    borderRadius: '20px',
    border: '1px solid #d3d3d3',
    padding: 10,
    textAlign: 'center',
    fontSize: '10px',
    marginLeft: 10
  }

  const mainSectionStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 10,
    width: '100%',
  }

  const tableStyle: CSSProperties = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }

  const mainTableKeyStyle: CSSProperties = {
    fontSize: 12,
    width: 100,
  }

  const mainTableValueStyle: CSSProperties = {
    fontSize: 12
  }

  const mainTableRowStyle: CSSProperties = {
    display: 'flex', 
    flexDirection: 'row',
    marginBottom: 10
  }

  const mainTableLastRowStyle: CSSProperties = {
    marginBottom: 0,
  }

  return (
    <html>
      <body style={bodyStyle}>
        <div style={mainSectionStyle}>
          <div style={tableStyle}>
            <div style={mainTableRowStyle}>
              <div style={mainTableValueStyle}>
                {customer_name}
                <br />
                {customer_street}
                <br />
                {customer_city}, {customer_state} {customer_zip}
              </div>
            </div>

            <div style={{
              ...mainTableRowStyle,
              ...mainTableLastRowStyle,
            }}>
              <div style={mainTableKeyStyle}>Remit Payment to:</div>
              <div style={mainTableValueStyle}>
                <b>Regular Mail:</b>
                <br />
                {`Loyal Assistant, Inc. dba Bravo Care`}
                <br />
                {`Dept. 911`}
                <br />
                {`P.O. Box 509141`}
                <br />
                {`San Diego, CA 92150`}
                <br />
                <br />
                <b>Expedited Mail:</b>
                <br />
                {`Care of: Loyal Assistant, Inc. dba Bravo Care Accounts Receivable`}
                <br />
                {`9116 Sunland Blvd`}
                <br />
                {`Sun Valley, CA 91352`}
              </div>
            </div>
          </div>

          <div style={infoBoxStyle}>
            <b>Please remit payment</b>
            <br />
            <b>through Wire to avoid</b>
            <br />
            <b>late fees on invoices</b>
            <br />
            <b>received</b>
            <br/> 
            <b>Wire funds to:</b>
            <br />
            {`Mission Valley Bank`}
            <br />
            {`9116 Sunland Blvd`}
            <br />
            {`Sun Valley, CA 91352`}
            <br />
            ABA Routing Number:
            <br />
            {`122243224`}
            <br />
            Account Number:
            <br />
            {`4004731`}
            <br />
            For Credit to:
            <br />
            Loyal Assistant, Inc.
          </div>
        </div>

        <div style={dividerStyle}></div>
      </body>
    </html>
  )
}

export default memo(FirstPageMainSection)
