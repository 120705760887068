import { Document, Page, Text, View } from "@react-pdf/renderer"
import { differenceInMinutes } from "date-fns"
import { isNil, startCase, toLower } from "lodash"
import moment from "moment"
import { FC, memo } from "react"
import { FetchFacilityInvoiceDataResponse } from "../../../../../@types/facility"

const TimesheetPDF: FC<FetchFacilityInvoiceDataResponse> = ({invoice: {customer_iana_time_zone}, payrolls}) => {
  const calcTotalBreak = (start: string, end: string) => {
    if (isNil(start) || isNil(end)) {
      return 0
    }
  
    return differenceInMinutes(new Date(end), new Date(start))
  }

  const today = moment()

  const columnWidth = {
    shiftDate: 50,
    orderId: 30,
    entryId: 30,
    type: 25,
    profId: 30,
    firstName: 80,
    lastName: 80,
    shiftStartTime: 45,
    shiftEndTime: 45,
    clockIn: 50,
    clockOut: 50,
    breakMinutes: 40,
    rate: 30,
    payType: 80,
    cancelComment: 80,
    hours: 30,
    total: 40,
    clockInSupervisorFirstName: 80,
    clockInSupervisorLastName: 80,
    clockInSupervisorDatetime: 80,
    clockOutSupervisorFirstName: 80,
    clockOutSupervisorLastName: 80,
    clockOutSupervisorDatetime: 80,
    breakStart: 80,
    breakEnd: 80,
    backToBackShift: 40,
    honoringLastRate: 40,
    adjustment: 40,
    adjustmentRunNo: 40,
  }

  return (
    <Document>
      <Page size='LETTER' orientation="landscape" style={{ paddingVertical: 8, paddingHorizontal: 8 }}>
        <View wrap={false} style={{flexDirection: 'row', fontSize: 3.5, justifyContent: 'center', alignItems: 'flex-end', backgroundColor: '#E8E8E8'}}>
          <Text style={{width: columnWidth.shiftDate, textAlign: 'center'}}>Shift Date</Text>
          <Text style={{width: columnWidth.orderId, textAlign: 'center'}}>Order ID</Text>
          <Text style={{width: columnWidth.entryId, textAlign: 'center'}}>Entry ID</Text>
          <Text style={{width: columnWidth.type, textAlign: 'center'}}>Type</Text>
          <Text style={{width: columnWidth.profId, textAlign: 'center'}}>Prof ID</Text>
          <Text style={{width: columnWidth.firstName, textAlign: 'center'}}>First Name</Text>
          <Text style={{width: columnWidth.lastName, textAlign: 'center'}}>Last Name</Text>
          <Text style={{width: columnWidth.shiftStartTime, textAlign: 'center'}}>Shift Start Time</Text>
          <Text style={{width: columnWidth.shiftEndTime, textAlign: 'center'}}>Shift End Time</Text>
          <Text style={{width: columnWidth.clockIn, textAlign: 'center'}}>Clock In</Text>
          <Text style={{width: columnWidth.clockOut, textAlign: 'center'}}>Clock Out</Text>
          <Text style={{width: columnWidth.breakMinutes, textAlign: 'center'}}>Break Minutes</Text>
          <Text style={{width: columnWidth.rate, textAlign: 'center'}}>Rate</Text>
          <Text style={{width: columnWidth.payType, textAlign: 'center'}}>Pay Type</Text>
          <Text style={{width: columnWidth.cancelComment}}>Cancel Comment</Text>
          <Text style={{width: columnWidth.hours, textAlign: 'center'}}>Hours</Text>
          <Text style={{width: columnWidth.total, textAlign: 'center'}}>Total</Text>
          <View style={{width: columnWidth.clockInSupervisorFirstName, flexDirection: 'column'}}>
            <Text style={{textAlign: 'center'}}>Clock In</Text>
            <Text style={{textAlign: 'center'}}>Supervisor First</Text>
            <Text style={{textAlign: 'center'}}>Name</Text>
          </View>
          <View style={{width: columnWidth.clockInSupervisorLastName, flexDirection: 'column'}}>
            <Text style={{textAlign: 'center'}}>Clock In</Text>
            <Text style={{textAlign: 'center'}}>Supervisor Last</Text>
            <Text style={{textAlign: 'center'}}>Name</Text>
          </View>
          <View style={{width: columnWidth.clockInSupervisorDatetime, flexDirection: 'column'}}>
            <Text style={{textAlign: 'center'}}>Clock In</Text>
            <Text style={{textAlign: 'center'}}>Supervisor Datetime</Text>
          </View>
          <View style={{width: columnWidth.clockOutSupervisorFirstName, flexDirection: 'column'}}>
            <Text style={{textAlign: 'center'}}>Clock Out</Text>
            <Text style={{textAlign: 'center'}}>Supervisor First</Text>
            <Text style={{textAlign: 'center'}}>Name</Text>
          </View>
          <View style={{width: columnWidth.clockOutSupervisorLastName, flexDirection: 'column'}}>
            <Text style={{textAlign: 'center'}}>Clock Out</Text>
            <Text style={{textAlign: 'center'}}>Supervisor Last</Text>
            <Text style={{textAlign: 'center'}}>Name</Text>
          </View>
          <View style={{width: columnWidth.clockOutSupervisorDatetime, flexDirection: 'column'}}>
            <Text style={{textAlign: 'center'}}>Clock Out</Text>
            <Text style={{textAlign: 'center'}}>Supervisor Datetime</Text>
          </View>
          <Text style={{width: columnWidth.breakStart, textAlign: 'center'}}>Break Start</Text>
          <Text style={{width: columnWidth.breakEnd, textAlign: 'center'}}>Break End</Text>
          <Text style={{width: columnWidth.backToBackShift, textAlign: 'center'}}>Back to Back Shift</Text>
          <Text style={{width: columnWidth.honoringLastRate, textAlign: 'center'}}>Honoring Last Rate</Text>
          <Text style={{width: columnWidth.adjustment, textAlign: 'center'}}>Adjustment</Text>
          <Text style={{width: columnWidth.adjustmentRunNo, textAlign: 'center'}}>Adjustment Run No.</Text>
        </View>
        
        {payrolls.map((payroll) => (
          <View key={payroll.payroll_id} wrap={false} style={{flexDirection: 'row', fontSize: 3.5, justifyContent: 'center', alignItems: 'center', marginTop: 2}}>
            <Text style={{width: columnWidth.shiftDate, textAlign: 'center'}}>
              {payroll.shift_date}
            </Text>
            <Text style={{width: columnWidth.orderId, textAlign: 'center'}}>
              {payroll.order_id}
            </Text>
            <Text style={{width: columnWidth.entryId, textAlign: 'center'}}>
              {payroll.entry_id}
            </Text>
            <Text style={{width: columnWidth.type, textAlign: 'center'}}>
              {payroll.job_type_abbrev}
            </Text>
            <Text style={{width: columnWidth.profId, textAlign: 'center'}}>
              {payroll.professional_id}
            </Text>
            <Text style={{width: columnWidth.firstName, textAlign: 'center'}}>
              {startCase(toLower(payroll.prof_first_name))}
            </Text>
            <Text style={{width: columnWidth.lastName, textAlign: 'center'}}>
              {startCase(toLower(payroll.prof_last_name))}
            </Text>
            <Text style={{width: columnWidth.shiftStartTime, textAlign: 'center'}}>
              {moment(`${today.format('YYYY-MM-DD')} ${payroll.shift_start_time}`).format('HH:mm:ss')}
            </Text>
            <Text style={{width: columnWidth.shiftEndTime, textAlign: 'center'}}>
              {moment(`${today.format('YYYY-MM-DD')} ${payroll.shift_end_time}`).format('HH:mm:ss')}
            </Text>
            <Text style={{width: columnWidth.clockIn, textAlign: 'center'}}>
              {isNil(payroll.adj_clock_in) ? '' : moment(payroll.adj_clock_in).utc().format('HH:mm:ss')}
            </Text>
            <Text style={{width: columnWidth.clockOut, textAlign: 'center'}}>
              {isNil(payroll.adj_clock_out) ? '' : moment(payroll.adj_clock_out).utc().format('HH:mm:ss')}
            </Text>
            <Text style={{width: columnWidth.breakMinutes, textAlign: 'center'}}>
              {calcTotalBreak(payroll.adj_break_1_start, payroll.adj_break_1_end)}
            </Text>
            <Text style={{width: columnWidth.rate, textAlign: 'center'}}>
              {payroll.has_worked_hours ? payroll.hourly_rate : ''}
            </Text>
            <Text style={{width: columnWidth.payType, textAlign: 'center'}}>
              {isNil(payroll.pay_type_name) ? '' : payroll.pay_type_name}
            </Text>
            <Text style={{width: columnWidth.cancelComment}}>
              {isNil(payroll.event_comments) ? '' : payroll.event_comments}
            </Text>
            <Text style={{width: columnWidth.hours, textAlign: 'center'}}>
              {payroll.has_worked_hours ? payroll.hours_worked : ''}
            </Text>
            <Text style={{width: columnWidth.total, textAlign: 'center'}}>
              {isNil(payroll.pay_amount) ? '' : parseFloat(payroll.pay_amount.toString()).toFixed(2)}
            </Text>
            <Text style={{width: columnWidth.clockInSupervisorFirstName, textAlign: 'center'}}>
              {isNil(payroll.clock_in_supervisor_firstname) ? '' : payroll.clock_in_supervisor_firstname.trim() === 'Bravo' ? 'Missed Punch' : startCase(toLower(payroll.clock_in_supervisor_firstname))}
            </Text>
            <Text style={{width: columnWidth.clockInSupervisorLastName, textAlign: 'center'}}>
              {isNil(payroll.clock_in_supervisor_lastname) ? '' : payroll.clock_in_supervisor_lastname.trim() === 'Care' ? 'Form' : startCase(toLower(payroll.clock_in_supervisor_lastname))}
            </Text>
            <Text style={{width: columnWidth.clockInSupervisorDatetime, textAlign: 'center'}}>
              {isNil(payroll.clock_in_supervisor_signed_datetime) ? '' : moment(payroll.clock_in_supervisor_signed_datetime).tz(isNil(customer_iana_time_zone) ? 'America/New_York' : customer_iana_time_zone).format('MM/DD/YYYY h:mmA')}
            </Text>
            <Text style={{width: columnWidth.clockOutSupervisorFirstName, textAlign: 'center'}}>
              {isNil(payroll.clock_out_supervisor_firstname) ? '' : payroll.clock_out_supervisor_firstname.trim() === 'Bravo' ? 'Missed Punch' :  startCase(toLower(payroll.clock_out_supervisor_firstname))}
            </Text>
            <Text style={{width: columnWidth.clockOutSupervisorLastName, textAlign: 'center'}}>
              {isNil(payroll.clock_out_supervisor_lastname) ? '' : payroll.clock_out_supervisor_lastname.trim() === 'Care' ? 'Form' : startCase(toLower(payroll.clock_out_supervisor_lastname))}
            </Text>
            <Text style={{width: columnWidth.clockOutSupervisorDatetime, textAlign: 'center'}}>
              {isNil(payroll.clock_out_supervisor_signed_datetime) ? '' : moment(payroll.clock_out_supervisor_signed_datetime).tz(isNil(customer_iana_time_zone) ? 'America/New_York' : customer_iana_time_zone).format('MM/DD/YYYY h:mmA')}
            </Text>
            <Text style={{width: columnWidth.breakStart, textAlign: 'center'}}>
              {isNil(payroll.adj_break_1_start) ? '' : moment(payroll.adj_break_1_start).utc().format('MM/DD/YYYY h:mmA')}
            </Text>
            <Text style={{width: columnWidth.breakEnd, textAlign: 'center'}}>
              {isNil(payroll.adj_break_1_end) ? '' : moment(payroll.adj_break_1_end).utc().format('MM/DD/YYYY h:mmA')}
            </Text>
            <Text style={{width: columnWidth.backToBackShift, textAlign: 'center'}}>
              {payroll.back_to_back_shift ? 'Yes' : 'No'}
            </Text>
            <Text style={{width: columnWidth.honoringLastRate, textAlign: 'center'}}>
              {payroll.honoring_last_rate ? 'Yes' : 'No'}
            </Text>
            <Text style={{width: columnWidth.adjustment, textAlign: 'center'}}>
              {payroll.adjustment ? 'Yes' : 'No'}
            </Text>
            <Text style={{width: columnWidth.adjustmentRunNo, textAlign: 'center'}}>
              {payroll.adjustment && !isNil(payroll.adjustment_run_no) ? payroll.adjustment_run_no : ''}
            </Text>
          </View>
        ))}
      </Page>
    </Document>
  )
}

export default memo(TimesheetPDF)
