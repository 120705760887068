import { BlobProvider, Document, PDFViewer } from "@react-pdf/renderer";
import axios from "axios";
import moment from 'moment-timezone';
import { useCallback, useState } from "react";
import { PayStubFacilityShiftSummary, PayStubProfessionalPayment, PayStubRow } from "../../../../@types/professional";
import PayStubFirstPage from "./Pages/PayStubFirstPage";

const API_ROOT = '/api/v1';

export const formatHours = (hours) => {
  return moment(hours, 'HH:mm').format('hh:mm A')
}

export const convertAggrupedObjectsToRows = (aggrupedObjects: Record<string, Record<string, PayStubFacilityShiftSummary>>
  ) => {
  return Object.keys(aggrupedObjects).reduce((result: PayStubRow[], shiftDate) => {
    const facilitiesDetails = Object.values(aggrupedObjects[shiftDate])

    facilitiesDetails.forEach((facility) => {
      const payTypes = Object.values(facility.pay_types)

      payTypes.forEach((payType) => {
        result.push({
          key: `${facility.facility_id}-${shiftDate}-${payType.pay_type_id}`,
          "facility_id": facility.facility_id,
          "shift_date": shiftDate,
          "start_time": facility.start_time,
          "end_time": facility.end_time,
          "facility_name": facility.facility_name,
          "total_amount": facility.total_amount,
          "total_hours": facility.total_hours,
          "pay_type_id": payType.pay_type_id,
          "pay_type_name": payType.pay_type_name,
          "pay_amount": payType.pay_amount,
          "hours_worked": payType.hours_worked
        })
      })
    })

    return result
  }, [])
}

const PayStubPDF = ({data}) => {
  return (
    <Document>
      <PayStubFirstPage {...data} />
    </Document>
  )
}

export const PayStubPDFViewer = ({data}) => {
  return (
    <div style={{
      width: '100%',
      height: 'auto'
    }}>
      <PDFViewer 
        showToolbar={true}
        style={{ 
          width: '100%',
          height: '100vh'
        }}
      >
        <PayStubPDF data={data} />
      </PDFViewer>
    </div>
  )
}

export const PayStubPDFGenerator = () => {
  const [pdfToken, setPDFToken] = useState('')
  const [authToken, setAuthToken] = useState('')
  const [data, setData] = useState<PayStubProfessionalPayment>()
  const [professionalApiURL, setProfessionalApiURL] = useState('')

  const saveBlob = useCallback((blob: Blob | null) => {
    // TODO: throw an error in case blob is null
    if (!blob) return

    const file = new File([blob], `paystub.pdf`)
    const formData = new FormData()
    formData.set('file', file)
    formData.append('token', pdfToken)
    formData.append('professionalApiURL', professionalApiURL)

    axios.post(`${API_ROOT}/professional/saveGeneratedPayStubPDF`, formData, {
      headers: {
        'authorization': authToken,
        'Content-Type': `multipart/form-data`
      },
    })
      .then(res => {
        console.log(res)
      })
      .catch(e => {
        console.error(e)
      })
  }, [pdfToken, authToken, professionalApiURL])

  const setTokens = useCallback(() => {
    const authToken = (document.getElementById('auth-token-input') as HTMLInputElement)?.value ?? ''
    const pdfToken = (document.getElementById('pdf-token-input') as HTMLInputElement)?.value ?? ''
    const data = (document.getElementById('data-input') as HTMLInputElement)?.value ?? ''
    const professionalApiURL = (document.getElementById('prof-api-url-input') as HTMLInputElement)?.value ?? ''

    setAuthToken(authToken)
    setPDFToken(pdfToken)
    setProfessionalApiURL(professionalApiURL)
    setData(JSON.parse(data))
  }, [])

  if (!authToken || authToken.length === 0 || !pdfToken || pdfToken.length === 0 || !data || !professionalApiURL || professionalApiURL.length === 0) {
    return (
      <div>
        <input id='auth-token-input' />
        <input id='pdf-token-input' />
        <input id='data-input' />
        <input id='prof-api-url-input' />
        <button id="set-tokens-btn" onClick={setTokens}>
          Set Tokens
        </button>
      </div>
    )
  }

  if (!data || !professionalApiURL || professionalApiURL.length === 0) {
    return <div id='loading'>Loading PDF...</div>
  }

  return (
    <div>
      <BlobProvider document={<PayStubPDF data={data} />}>
        {({ blob, loading, error }) => {
            if (loading) {
              return <div id="loading">Loading PDF...</div>
            }

            if (error) {
              return <div id="error">{error.message}</div>
            }

            return (
              <button id="save-pdf-button" onClick={() => saveBlob(blob)}>
                Save PDF
              </button>
            )
          }
        }
      </BlobProvider>

      {/* <PayStubPDFViewer data={data} /> */}
    </div>
  )
}
