import { memo } from "react"
import { formatHours } from "../../.."
import { formatCurrency } from "../../../../../helpers/number.helper"
import { bodyStyle, columnWidth, payStubItemHeader, payStubSection, paymentDetailsTableCell, paymentDetailsTableFirstCell, paymentDetailsTableGrayRow, paymentDetailsTableHightlightRow, paymentDetailsTableTotalsRow, paymentDetailsTableWhiteRow } from "../../../styles"

const PayStubAdjustmentsTable = ({adjustment, index, showFacilityHeader, showTotals}) => {
  const getRowStyles = (index) => {
    return index % 2 === 0 ? paymentDetailsTableWhiteRow : paymentDetailsTableGrayRow
  }

  const getHours = () => {
    return `${adjustment.hours_worked < 0 ? '-' : ''}${formatCurrency(adjustment.hours_worked, true)}`
  }

  const getPayAmount = () => {
    return `${adjustment.pay_amount < 0 ? '-' : ''}$${formatCurrency(adjustment.pay_amount, true)}`
  }

  const getTotalHours = () => {
    return `${adjustment.total_hours < 0 ? '-' : ''}${formatCurrency(adjustment.total_hours, true)}`
  }

  const getTotalPayAmount = () => {
    return `${adjustment.total_amount < 0 ? '-' : ''}$${formatCurrency(adjustment.total_amount, true)}`
  }

  return (
    <html>
      <body style={bodyStyle}>
        {index === 0 ? (
          <div style={{width: '100%'}}>
            <div 
              style={payStubSection}
            >
              {`Adjustments to Previous Transactions`}
            </div>
          </div>
        ) : null}

        {showFacilityHeader ? (
          <div style={{width: '100%'}}>
            <div 
              style={payStubItemHeader}
            >
              <div 
                style={{flex: 1}}
              >
                {`Facility: ${adjustment.facility_name}`}
              </div>
              
              <div 
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                {`Shift Date: ${adjustment.shift_date}`}
              </div>
              
              <div 
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center', 
                  textAlign: 'right',
                }}
              >
                {`Shift: ${formatHours(adjustment.start_time)} to ${formatHours(adjustment.end_time)}`}
              </div>
            </div>

            <div style={paymentDetailsTableHightlightRow}>
              <div style={{
                ...paymentDetailsTableFirstCell,
              }}>
                {`Pay Type`}	
              </div>

              <div style={{
                ...paymentDetailsTableCell,
                width: columnWidth.hours,
              }}>
                {`Hours`}
              </div>

              <div style={{
                ...paymentDetailsTableCell,
                width: columnWidth.amount,
              }}>
                {`Pay Amount`}
              </div>
            </div>
          </div>
        ) : null}

        <div style={getRowStyles(index)}>
          <div style={{
            ...paymentDetailsTableFirstCell,
          }}>
            {adjustment.pay_type_name}
          </div>
          
          <div style={{
            ...paymentDetailsTableCell,
            width: columnWidth.hours,
          }}>
            {getHours()}
          </div>

          <div style={{
            ...paymentDetailsTableCell,
            width: columnWidth.amount,
          }}>
            {getPayAmount()}
          </div>
        </div>
        
        {showTotals ? (
          <div style={{width: '100%'}}>
            <div style={paymentDetailsTableTotalsRow}>
              <div style={{
                ...paymentDetailsTableFirstCell,
              }}>
                {`Totals`}
              </div>

              <div style={{
                ...paymentDetailsTableCell,
                width: columnWidth.hours,
              }}>
                {getTotalHours()}
              </div>

              <div style={{
                ...paymentDetailsTableCell,
                width: columnWidth.amount,
              }}>
                {getTotalPayAmount()}
              </div>
            </div>
          </div>
        ) : null}
      </body>
    </html>
  )
}

export default memo(PayStubAdjustmentsTable)
