import { CSSProperties, memo } from "react"

const PayStubFirstPageHeader = () => {
  const bodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  }
  
  const paymentHeaderParagraphyStyle: CSSProperties = {
    margin: 0,
    padding: 0,
    fontSize: '10px',
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  }

  return (
    <html>
      <body style={bodyStyle}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <div
            style={{
              flex: 1,
            }}
          >
            <img
              src="/img/bravoCareLogo_desktop.png"
              alt="BravoCare Logo"
              style={{
                width: 150,
              }}
            />
          </div>

          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-end',
              textAlign: 'right',
            }}
          >
            <div style={{
              ...paymentHeaderParagraphyStyle,
              color: '#2b78e4',
            }}>{`Loyal Assistant, Inc. dba Bravo Care`}</div>
            <div style={paymentHeaderParagraphyStyle}>{`(800) 483-0663`}</div>
            <div style={paymentHeaderParagraphyStyle}>{`Payroll@BravoCare.com`}</div>
            <div style={paymentHeaderParagraphyStyle}>{`www.BravoCare.com`}</div>
          </div>
        </div>
        
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 10,
          }}
        >
          <div 
            style={{ 
              fontSize: '16px',
              flex: 1,
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            Pay Stub
          </div>
        </div>
      </body>
    </html>
  )
}

export default memo(PayStubFirstPageHeader)
