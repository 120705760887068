import { isEmpty, isNil } from "lodash"
import moment from "moment"
import { FC, memo } from "react"
import { PayHistoryPaymentDetail } from "../../../../../../../@types/professional"
import { formatCurrency } from "../../../../../helpers/number.helper"
import { bodyStyle, columnWidth, paymentDetailsTableCell, paymentDetailsTableFirstCell, paymentDetailsTableGrayRow, paymentDetailsTableWhiteRow } from "../../../styles"

const PayHistoryPaymentTableHTML: FC<{
  payment: Omit<PayHistoryPaymentDetail, 'summary'>
}> = ({payment}) => {

  const getRowStyles = (rowStyles) => {
    return rowStyles === 'even' ? paymentDetailsTableWhiteRow : paymentDetailsTableGrayRow
  }

  // const getFee = () => {
  //   if (payment.payment_transaction_fee <= 0) {
  //     return ''
  //   }

  //   return `${
  //     payment.payment_transaction_fee < 0
  //       ? '-'
  //       : ''
  //   }$${formatCurrency(payment.payment_transaction_fee)}`
  // }

  const getPayAmount = () => {
    if (payment.amount <= 0) {
      return '$0.00'
    }

    return `${payment.amount < 0 ? '-' : ''}$${formatCurrency(payment.amount, true)}`
  }

  const getTransactionId = () => {
    if (!isNil(payment.external_transaction_id)) {
      return payment.external_transaction_id
    }

    return payment.payment_transaction_id
  }

  return (
    <html>
      <body style={bodyStyle}>
        <div style={{
          ...getRowStyles(payment.rowStyles),
          height: 16
        }}>
          <div style={{
            ...paymentDetailsTableFirstCell,
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
            <div>
              <img
                src="/img/arrow_right.png"
                alt="Payment Arrow"
                style={{
                  width: 6,
                }}
              />
            </div>
            <div style={{marginLeft: 2}}>{`Payment:`}</div>
            <div style={{marginLeft: 8}}>{`Paid Date: ${moment(payment.paid_date).format('MM/DD/YYYY')}`}</div>
            <div style={{marginLeft: 8}}>
              {`Via: ${payment.payment_company_name === 'Routable' ? 'Direct Deposit' : payment.payment_company_name}`}
            </div>
            {!isNil(payment.payment_account) && !isEmpty(payment.payment_account) ? (
              <div style={{marginLeft: 8}}>{`Accnt: ${payment.payment_account.slice(-4)}`}</div>
            ) : null}
            <div style={{marginLeft: 8}}>{`Trans #: ${getTransactionId()}`}</div>
          </div>

          <div style={{
            ...paymentDetailsTableCell,
            width: columnWidth.amount,
          }}>
            {getPayAmount()}
          </div>
        </div>
      </body>
    </html>
  )
}

export default memo(PayHistoryPaymentTableHTML)
