import { Page, View } from "@react-pdf/renderer"
import Decimal from "decimal.js"
import { isNil } from "lodash"
import { CSSProperties, FC, memo } from "react"
import ReactDOMServer from "react-dom/server"
import Html from "react-pdf-html"
import { FetchFacilityInvoiceDataResponse, LateFeeInvoiceRow } from "../../../../../../@types/facility"
import { formatCurrency } from "../../../../helpers/number.helper"
import FirstPageFooter from "./FirstPageFooter"
import FirstPageHeader from "./FirstPageHeader"
import FirstPageMainSection from "./FirstPageMainSection"

const FirstPage: FC<FetchFacilityInvoiceDataResponse> = ({invoice, companyAddress}) => {
  const bodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
  }

  const invoiceMainTableRowStyle: CSSProperties = {
    display: 'flex', 
    flexDirection: 'row',
    marginBottom: 10,
    width: '100%',
    minHeight: 15,
  }

  const invoiceAmountsTableRow: CSSProperties = {
    ...invoiceMainTableRowStyle,
    fontSize: '10px',
    marginBottom: 0,
    width: '100%',
  }

  const invoiceAmountsTableHightlightRow: CSSProperties = {
    ...invoiceAmountsTableRow,
    border: '1px solid black',
    backgroundColor: '#2b78e4',
    color: 'white',
    width: '100%',
  }

  const invoiceAmountsTableWhiteRow: CSSProperties = {
    ...invoiceAmountsTableRow,
    backgroundColor: 'white',
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
    width: '100%',
  }

  const invoiceAmountsTableGrayRow: CSSProperties = {
    ...invoiceAmountsTableWhiteRow,
    backgroundColor: '#cccccc',
    width: '100%',
  }

  const invoiceAmountsTableCell: CSSProperties = {
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 1,
    paddingBottom: 1,
    borderLeft: '1px solid black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'right',
  }

  const invoiceAmountsCenteredTableCell: CSSProperties = {
    ...invoiceAmountsTableCell,
    justifyContent: 'center',
    textAlign: 'center'
  }

  const invoiceAmountsTextTableCell: CSSProperties = {
    ...invoiceAmountsTableCell,
    justifyContent: 'flex-start',
    textAlign: 'left'
  }

  const invoiceAmountsTableFirstCell: CSSProperties = {
    ...invoiceAmountsTextTableCell,
    borderLeftWidth: 0,
    flex: 1,
  }

  const invoiceAmountsTableTotalContainerStyle: CSSProperties = {
    width: '100%',
    marginTop: 15,
    textAlign: 'right',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  }

  const invoiceAmountsTableTotalStyle: CSSProperties = {
    fontSize: '16px',
    textAlign: 'right',
  }

  const footerViewStyle: any = {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 20
  }

  const columns = [
    {
      name: 'Invoice',
      key: 'invoiceNumber',
      align: 'left',
    },
    {
      name: 'Invoice Date',
      key: 'invoiceDate',
      align: 'center',
      width: 80,
    },
    {
      name: 'Invoice Amount',
      key: 'invoiceAmount',
      align: 'right',
      width: 80,
    },
    {
      name: 'Invoice Balance',
      key: 'invoiceBalance',
      align: 'right',
      width: 80,
    },
    {
      name: 'Periods Late',
      key: 'periodsLate',
      align: 'center',
      width: 60,
    },
    {
      name: 'Calculated Till',
      key: 'calculatedTill',
      align: 'center',
      width: 150,
    },
    {
      name: 'Late Charges',
      key: 'lateCharges',
      align: 'right',
      width: 80,
    },
  ]

  const totalLateCharges = `$${formatCurrency(invoice.invoice_amount)}`
  const invoiceTotalString = `Late Fee Invoice Total: ${totalLateCharges}`
  let totalAmount = new Decimal(0.00).toNumber()
  let totalBalance = new Decimal(0.00).toNumber()

  const rows: LateFeeInvoiceRow[] = invoice.memo.split(';').reduce((result: LateFeeInvoiceRow[], row: string, currentIndex: number) => {
    if (!row || row.trim().length === 0 || !row.includes(',')) {
      return result
    }

    try {
      const values = row.split(',')

      const formattedRow: LateFeeInvoiceRow = {
        invoiceNumber: values[0],
        periodsLate: parseInt(values[1]).toString(),
        invoiceBalance: `$${formatCurrency(new Decimal(values[2]).toNumber())}`,
        calculatedTill: values[3],
        lateCharges: `$${formatCurrency(new Decimal(values[4]).toNumber())}`,
        invoiceAmount: `$${formatCurrency(new Decimal(values[5]).toNumber())}`,
        invoiceDate: values[6],
      }

      totalAmount = new Decimal(totalAmount).plus(values[5]).toNumber()
      totalBalance = new Decimal(totalBalance).plus(values[2]).toNumber()

      result.push(formattedRow)
    } catch (e) {
      console.log('[ERROR][LateFeeInvoicePDF] Failed to parse row', row, currentIndex)
      console.error(e)
      
      result.push({
        invoiceNumber: '',
        periodsLate: currentIndex.toString(),
        invoiceBalance: '',
        calculatedTill: row,
        lateCharges: '',
        invoiceAmount: '',
        invoiceDate: new Date().toISOString(),
      })
    }

    return result;
  }, [])

  return (
    <Page size='LETTER' orientation="portrait" style={{ paddingVertical: 20, paddingHorizontal: 8, paddingBottom: 40 }}>
      <View wrap={false}>
        <Html resetStyles>
          {ReactDOMServer.renderToStaticMarkup(
            <FirstPageHeader 
              invoiceNumber={invoice.invoice_no} 
              invoiceDate={invoice.invoice_date} 
              termDesc={invoice.term_desc} 
              isCreditMemo={isNil(invoice.credit_memo) ? false : invoice.credit_memo}
            />
          )}
        </Html>
      </View>

      <View wrap={false}>
        <Html resetStyles>
          {ReactDOMServer.renderToStaticMarkup(
            <FirstPageMainSection invoice={invoice} />
          )}
        </Html>
      </View>
      
      {rows.map((entry, index, array) => {
        const key = `${entry.invoiceNumber}-${index}`

        const rowStyles = index % 2 === 0 ? invoiceAmountsTableWhiteRow : invoiceAmountsTableGrayRow

        const nextRowStyles = (index + 1) % 2 === 0 ? invoiceAmountsTableWhiteRow : invoiceAmountsTableGrayRow

        return (
          <View key={key} wrap={false}>
            <Html resetStyles>
              {ReactDOMServer.renderToStaticMarkup(
                <html>
                  <body style={bodyStyle}>
                    {index === 0 ? (
                      <div style={invoiceAmountsTableHightlightRow}>
                        {columns.map((column, columnIndex) => (
                          <div key={column.key} style={{
                            ...invoiceAmountsTableCell,
                            ...(columnIndex === 0 ? invoiceAmountsTableFirstCell : {}),
                            justifyContent: 'center',
                            textAlign: 'center',
                            ...(column.width ? { width: column.width } : {})
                          }}>{column.name}</div>
                        ))}
                      </div>
                    ) : null}

                    <div style={rowStyles}>
                      {columns.map((column, columnIndex) => (
                          <div key={`${column.key}-${columnIndex}`} style={{
                            ...invoiceAmountsTableCell,
                            ...(columnIndex === 0 ? invoiceAmountsTableFirstCell : {}),
                            ...(column.align === 'left' ? invoiceAmountsTextTableCell : column.align === 'center' ? invoiceAmountsCenteredTableCell : {}),
                            ...(column.width ? { width: column.width } : {})
                          }}>{entry[column.key]}</div>
                        ))}
                    </div>

                    {index === array.length - 1 ? (
                      <div style={nextRowStyles}>
                        {columns.map((column, columnIndex) => (
                          <div key={`${column.key}-${columnIndex}-subtotal`} style={{
                            ...invoiceAmountsTableCell,
                            ...(columnIndex === 0 ? invoiceAmountsTableFirstCell : {}),
                            ...(column.width ? { width: column.width } : {})
                          }}><div>{` `}</div></div>
                        ))}
                      </div>
                    ) : null}

                    {index === array.length - 1 ? (
                      <div style={{
                        ...rowStyles,
                        borderTop: '1px solid black',
                        borderBottom: '1px solid black'
                      }}>
                        <div style={{
                          ...invoiceAmountsTableCell,
                          ...invoiceAmountsTableFirstCell,
                          flex: 1,
                        }}>{`Total`}</div>

                        <div style={{
                          ...invoiceAmountsTableCell,
                          width: columns[1].width,
                        }}>{` `}</div>

                        <div style={{
                          ...invoiceAmountsTableCell,
                          width: columns[2].width,
                        }}>{`$${formatCurrency(totalAmount)}`}</div>

                        <div style={{
                          ...invoiceAmountsTableCell,
                          width: columns[3].width,
                        }}>{`$${formatCurrency(totalBalance)}`}</div>

                        <div style={{
                          ...invoiceAmountsTableCell,
                          width: columns[4].width,
                        }}>{` `}</div>

                        <div style={{
                          ...invoiceAmountsTableCell,
                          width: columns[5].width,
                        }}>{` `}</div>

                        <div style={{
                          ...invoiceAmountsTableCell,
                          width: columns[6].width,
                        }}>{totalLateCharges}</div>
                      </div>
                    ) : null}

                    {index === array.length - 1 ? (
                      <div style={invoiceAmountsTableTotalContainerStyle}>
                        <div style={invoiceAmountsTableTotalStyle}><strong>{invoiceTotalString}</strong></div>
                      </div>
                    ) : null}
                  </body>
                </html>
              )}
            </Html>
          </View>
        )
      })}

      <View wrap={false} fixed style={footerViewStyle}>
        <Html resetStyles>
          {ReactDOMServer.renderToStaticMarkup(
            <FirstPageFooter companyAddress={companyAddress} />
          )}
        </Html>
      </View>
    </Page>
  )
}

export default memo(FirstPage)
